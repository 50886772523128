import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { enableLocationSelection } from '../../config';
import { getPreviousStep, getCurrentIndex, getSteps, getHideNav } from '../../helpers/nav';
import { CheckMark, ChevronLeft } from './images';

const Nav = ({ steps, previousStep, current, hideNav, hiddenSteps, history, intl }) => {
  if (hideNav || current <= hiddenSteps) {
    return null;
  }

  const goBack = (ev) => {
    ev.preventDefault();
    history.push(previousStep);
  };

  return (
    <div className="cb-nav">
      {previousStep && (
        <a href="#" onClick={goBack} className="cb-nav-item previous" title={intl.formatMessage({ id: 'back' })}>
          <ChevronLeft />
        </a>
      )}
      {steps.slice(current - 1).map((item, index) => {
        const currentStep = current - hiddenSteps;
        const step = index + currentStep;
        const className = classNames({
          'cb-nav-item': true,
          active: step === currentStep,
          previous: step < currentStep
        });
        return (
          <div key={step} className={className}>{step}</div>
        );
      })}
      <div className="cb-nav-item"><CheckMark /></div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const index = getCurrentIndex(state, props);
  const previousStep = getPreviousStep(state, props);

  return {
    previousStep,
    current: index + 1,
    steps: getSteps(state),
    hideNav: getHideNav(state, props),
    hiddenSteps: enableLocationSelection() ? 1 : 0
  };
};

export default injectIntl(connect(mapStateToProps)(Nav));
