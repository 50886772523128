export const dayPickerClassNames = {
  container: 'cb-daypicker',
  wrapper: 'cb-daypicker-wrapper',
  interactionDisabled: 'cb-daypicker--interactionDisabled',
  months: 'cb-daypicker-Months',
  month: 'cb-daypicker-Month',

  navBar: 'cb-daypicker-NavBar',
  navButtonPrev: 'cb-daypicker-NavButton cb-daypicker-NavButton--prev',
  navButtonNext: 'cb-daypicker-NavButton cb-daypicker-NavButton--next',
  navButtonInteractionDisabled: 'cb-daypicker-NavButton--interactionDisabled',

  caption: 'cb-daypicker-Caption',
  weekdays: 'cb-daypicker-Weekdays',
  weekdaysRow: 'cb-daypicker-WeekdaysRow',
  weekday: 'cb-daypicker-Weekday',
  body: 'cb-daypicker-Body',
  week: 'cb-daypicker-Week',
  weekNumber: 'cb-daypicker-WeekNumber',
  day: 'cb-daypicker-Day',
  footer: 'cb-daypicker-Footer',
  todayButton: 'cb-daypicker-TodayButton',

  today: 'today',
  selected: 'selected',
  disabled: 'disabled',
  outside: 'outside'
};

export const dayPickerInputClassNames = {
  container: 'cb-daypicker-input',
  overlayWrapper: 'cb-daypicker-input-OverlayWrapper',
  overlay: 'cb-daypicker-input-Overlay'
};
