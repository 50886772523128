export const SET_LOADING = 'SET_LOADING';
export const RESET_LOADING = 'RESET_LOADING';

export function setLoading() {
  return {
    type: SET_LOADING
  };
}

export function resetLoading() {
  return {
    type: RESET_LOADING
  };
}
