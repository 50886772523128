import React from 'react';
import { CheckMark } from './images';

const CheckBox = ({ checked }) => {
  return checked ? (
    <div className="cb-checkbox checked">
      <CheckMark />
    </div>
  ) : (
    <div className="cb-checkbox" />
  );
};

export default CheckBox;
