import axios from 'axios';
import config from '../config';

export function networkDelay() {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 1000);
  });
}

function getJsonHeaders() {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-ClientoWidgetVersion': 'v2'
    }
  };
}

function prefixUrlError() {
  throw new Error('Invalid configuration parameters. Please verify properties set with cbk()');
}

export function prefixUrl(url, overrideId) {
  const { partner, id, stableId, org, loc, api } = config;
  const stringId = typeof id === 'string' ? id : null;
  const partnerId = overrideId || stableId || stringId;

  if (partner && partnerId) {
    return `${api}/api/v2/partner/${partner}/${partnerId}${url}`;
  }
  if (org && loc) {
    return `${api}/api/v2/locations/${org}/${loc}${url}`;
  }
  return prefixUrlError();
}

export function prefixVipUrl(url) {
  const { resourceHash, api } = config;

  if (resourceHash) {
    return `${api}/api/vip${url}`;
  }
  return prefixUrlError();
}

export function prefixBaseUrl(url) {
  const { api } = config;

  return `${api}/api/v2${url}`;
}

export function getJson(url) {
  return axios.get(url, getJsonHeaders());
}

export function postJson(url, data) {
  return axios.post(url, JSON.stringify(data), getJsonHeaders());
}
