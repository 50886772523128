import React from 'react';
import { createPortal } from 'react-dom';
import { useKeydown, usePreventBodyScroll } from '@Helpers/hooks';
import { XMark } from './images';

const Modal = ({ title, children, onClose }) => {
  useKeydown(27, onClose);
  usePreventBodyScroll();

  return createPortal((
    <div className="cb-modal-container">
      <div className="cb-modal-content">
        <button type="button" className="cb-modal-close" onClick={onClose}>
          <XMark />
        </button>
        <h2>{title}</h2>
        {children}
      </div>
    </div>
  ), document.body);
};

export default Modal;
