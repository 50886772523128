import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getPaymentEnabled, getShowExVatInfo } from '../../helpers/payment';
import Logo from './logo';
import PrivacyPolicy from './privacy-policy';

const Footer = ({ stableId, pricesExVat, showExVatInfo, paymentEnabled }) => (
  <div className="cb-footer">
    {paymentEnabled && pricesExVat && showExVatInfo && (
      <div className="cb-ex-vat-info">
        <FormattedMessage id="footer.exVatInfo" />
      </div>
    )}
    <Logo />
    {stableId && <PrivacyPolicy stableId={stableId} />}
  </div>
);

const mapStateToProps = (state, props) => {
  const { settings } = state;

  return {
    stableId: settings.get('stableId'),
    pricesExVat: settings.get('pricesExVat'),
    paymentEnabled: getPaymentEnabled(state),
    showExVatInfo: getShowExVatInfo(state, props)
  };
};

export default connect(mapStateToProps)(Footer);
