import React from 'react';
import ReactMarkdown from 'react-markdown';

const Markdown = ({ source, className }) => (
  <ReactMarkdown className={className} linkTarget="_blank">
    {String(source)}
  </ReactMarkdown>
);

export default Markdown;
