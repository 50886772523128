import { postJson, prefixUrl } from '../helpers/network';
import { getStripe } from '../helpers/stripe';

export const SET_PAYMENT_PROVIDER = 'SET_PAYMENT_PROVIDER';
export const SET_PAYMENT_REF_DATA = 'SET_PAYMENT_REF_DATA';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_PAYMENT_INTENT = 'SET_PAYMENT_INTENT';
export const RESET_PAYMENT_METHOD = 'RESET_PAYMENT_METHOD';
export const KLARNA_SESSION_SUCCESS = 'KLARNA_SESSION_SUCCESS';
export const REMOVE_KLARNA_METHOD = 'REMOVE_KLARNA_METHOD';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';

export function setPaymentProvider(paymentProvider) {
  return {
    type: SET_PAYMENT_PROVIDER,
    paymentProvider
  };
}

export function setPaymentRefData(paymentRefData) {
  return {
    type: SET_PAYMENT_REF_DATA,
    paymentRefData
  };
}

export function setPaymentMethod(paymentMethod) {
  return {
    type: SET_PAYMENT_METHOD,
    paymentMethod
  };
}

export function setPaymentIntent(paymentIntent) {
  return {
    type: SET_PAYMENT_INTENT,
    paymentIntent
  };
}

export function resetPaymentMethod() {
  return {
    type: RESET_PAYMENT_METHOD
  };
}

export function createPaymentMethod(card) {
  return (dispatch) => {
    const stripe = getStripe();
    return stripe.createPaymentMethod('card', card)
      .then(({ paymentMethod, error }) => {
        if (paymentMethod) {
          dispatch(setPaymentMethod(paymentMethod));
        } else {
          throw error;
        }
      });
  };
}

export function handleCardAction(clientSecret) {
  return (dispatch) => {
    const stripe = getStripe();
    return stripe.handleCardAction(clientSecret)
      .then(({ paymentIntent, error }) => {
        if (paymentIntent) {
          dispatch(setPaymentIntent(paymentIntent));
        } else {
          dispatch(resetPaymentMethod());
          throw error;
        }
      });
  };
}

export function getOrCreateKlarnaSession() {
  return (dispatch, getState) => {
    const { booking, payment } = getState();
    const slot = booking.get('slot');
    const klarnaSession = payment.get('klarnaSession');

    if (!slot || klarnaSession) {
      return Promise.resolve(klarnaSession);
    }
    return postJson(prefixUrl('/klarna/session'), { slotKey: slot.key })
      .then(({ data }) => {
        dispatch({ type: KLARNA_SESSION_SUCCESS, klarnaSession: data });
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function removeKlarnaMethod(method) {
  return (dispatch) => {
    dispatch({ type: REMOVE_KLARNA_METHOD, method });
    return Promise.resolve();
  };
}

export function addVoucherCode(voucherCode) {
  return (dispatch, getState) => {
    const { booking } = getState();
    const slot = booking.get('slot');

    return postJson(prefixUrl('/booking/voucher'), { slotKey: slot.key, voucherCode })
      .then(({ data }) => {
        dispatch({ type: SET_PAYMENT_DETAILS, ...data });
        return Promise.resolve(data);
      });
  };
}

export function removeVoucher() {
  return (dispatch, getState) => {
    const { booking } = getState();
    const slot = booking.get('slot');

    return postJson(prefixUrl('/booking/voucher'), { slotKey: slot.key, voucherCode: null })
      .then(({ data }) => {
        dispatch({ type: SET_PAYMENT_DETAILS, ...data });
        return Promise.resolve(data);
      });
  };
}
