import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { enableLocationSelection } from '../../config';
import { addBookingService, clearAddonServices, removeBookingService, resetBookingService } from '../../actions/booking';
import {
  getGroupedServices, getAvailableServiceIds, getBookableServiceIds, getSelectedResourceIds,
  getSelectedServices, getAvailableAddonServiceIds
} from '../../helpers/booking';
import { getNextStep, getShowResourceStep, getShowServiceStep, Step } from '../../helpers/nav';
import { getPreference } from '../../helpers/settings';
import { web } from '../../helpers/preference-keys';
import { ChevronRight } from '../base/images';
import ServiceGroup from './service-group';
import ResourceList from '../resource/resource-list';
import AddonService from './addon-service';

class Service extends React.Component {
  state = {
    showAddons: false
  };

  componentDidMount() {
    this.resetSelectedServices(true);
  }

  resetSelectedServices = (clearAll = false) => {
    const { multipleServices, selectedServices } = this.props;

    if (selectedServices) {
      this.props.clearAddonServices();

      if (!multipleServices || clearAll) {
        this.props.resetBookingService();
      }
    }
  };

  serviceSelected = (service) => {
    const { multipleServices, selectedServices, availableServiceIds } = this.props;

    const serviceId = service.get('serviceId');
    const selected = selectedServices && selectedServices.has(serviceId);

    if (!availableServiceIds.includes(serviceId)) {
      return;
    }

    if (multipleServices) {
      if (selected) {
        this.props.removeBookingService(service);
      } else {
        this.props.addBookingService(service);
      }
    } else {
      this.props.addBookingService(service);
      setTimeout(() => this.navigateNext(), 0);
    }
  };

  navigateNext = () => {
    const { showAddons } = this.state;
    const { availableAddonServiceIds } = this.props;
    const hasAddonServices = availableAddonServiceIds && !availableAddonServiceIds.isEmpty();

    if (showAddons || !hasAddonServices) {
      this.props.history.push(this.props.nextStep);
    } else {
      this.setState({ showAddons: true });
    }
  };

  cancelAddons = () => {
    this.setState({ showAddons: false });
    this.resetSelectedServices();
  };

  render() {
    const { showAddons } = this.state;
    const {
      hasSelectedLocation, alwaysShowDescription, collapseGroups,
      multipleServices, groupedServices, selectedServices, availableServiceIds,
      showResourceStep, selectedResourceIds, showServiceStep, bookableServiceIds
    } = this.props;

    const hasSelectedServices = selectedServices && !selectedServices.isEmpty();
    const continueEnabled = multipleServices && hasSelectedServices;

    if (enableLocationSelection() && !hasSelectedLocation) {
      return <Redirect to={Step.Location} />;
    }
    if (showResourceStep && !selectedResourceIds) {
      return <Redirect to={Step.Resource} />;
    }
    if (!showServiceStep) {
      return <Redirect to={Step.Calendar} />;
    }

    return (
      <>
        <div className="cb-header">
          <h2><FormattedMessage id="service.heading" /></h2>
        </div>
        {showAddons && (
          <AddonService
            availableServiceIds={availableServiceIds}
            bookableServiceIds={bookableServiceIds}
            alwaysShowDescription={alwaysShowDescription}
            onContinue={this.navigateNext}
            onCancel={this.cancelAddons}
          />
        )}
        <ResourceList />
        {groupedServices.entrySeq().map(([group, services]) => {
          return (
            <ServiceGroup
              key={group}
              name={group}
              services={services}
              multipleServices={multipleServices}
              selectedServices={selectedServices}
              availableServiceIds={availableServiceIds}
              bookableServiceIds={bookableServiceIds}
              alwaysShowDescription={alwaysShowDescription}
              serviceSelected={this.serviceSelected}
              collapseGroups={collapseGroups && groupedServices.size > 1}
              showHeader={collapseGroups || groupedServices.size > 1}
            />
          );
        })}
        {groupedServices.isEmpty() && (
          <div className="cb-service-empty">
            <FormattedMessage id="service.noServices" />
          </div>
        )}
        {multipleServices && !showAddons && (
          <div className="cb-service-button-container cb-service-button-sticky">
            <button type="button" className="cb-button" disabled={!continueEnabled} onClick={this.navigateNext}>
              <FormattedMessage id="continue" />
              <ChevronRight />
            </button>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { booking, settings } = state;

  return {
    hasSelectedLocation: !settings.isEmpty(),
    selectedResourceIds: getSelectedResourceIds(state),
    showResourceStep: getShowResourceStep(state),
    showServiceStep: getShowServiceStep(state),
    alwaysShowDescription: getPreference(settings, web.alwaysShowDescription),
    collapseGroups: getPreference(settings, web.collapseGroups),
    availableServiceIds: getAvailableServiceIds(state),
    availableAddonServiceIds: getAvailableAddonServiceIds(state),
    bookableServiceIds: getBookableServiceIds(state),
    groupedServices: getGroupedServices(state),
    selectedServices: getSelectedServices(state),
    multipleServices: booking.get('multipleServices'),
    nextStep: getNextStep(state, props)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBookingService: (service) => dispatch(addBookingService(service)),
    removeBookingService: (service) => dispatch(removeBookingService(service)),
    resetBookingService: () => dispatch(resetBookingService()),
    clearAddonServices: () => dispatch(clearAddonServices())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Service);
