import { createSelector } from 'reselect';
import { getCurrentIndex, getStep, Step } from './nav';
import { getFeature } from './settings';

export const PaymentOption = {
  Stripe: 'Stripe',
  Swish: 'Swish',
  KlarnaPayLater: 'KlarnaPayLater',
  KlarnaPayOverTime: 'KlarnaPayOverTime'
};

export const KlarnaMethod = {
  PayNow: 'pay_now',
  PayLater: 'pay_later',
  PayOverTime: 'pay_over_time'
};

export const getPaymentEnabled = createSelector(
  state => state.settings,
  (settings) => {
    return getFeature(settings, 'EnableEcomPayments');
  }
);

export const getPaymentOptions = createSelector(
  state => state.booking.get('paymentProviders'),
  (paymentProviders) => {
    return Object.keys(PaymentOption)
      .filter(key => paymentProviders?.some(p => key.includes(p)));
  }
);

export const getKlarnaPayLaterEnabled = createSelector(
  getPaymentOptions,
  state => state.payment.get('klarnaSession'),
  (paymentOptions, klarnaSession) => {
    return paymentOptions.includes(PaymentOption.KlarnaPayLater)
      && klarnaSession?.paymentMethodCategories?.includes(KlarnaMethod.PayLater);
  }
);

export const getKlarnaPayOverTimeEnabled = createSelector(
  getPaymentOptions,
  state => state.payment.get('klarnaSession'),
  (paymentOptions, klarnaSession) => {
    return paymentOptions.includes(PaymentOption.KlarnaPayOverTime)
      && klarnaSession?.paymentMethodCategories?.includes(KlarnaMethod.PayOverTime);
  }
);

export const getIsValidPaymentProvider = createSelector(
  state => state.payment.get('paymentProvider'),
  getKlarnaPayLaterEnabled,
  getKlarnaPayOverTimeEnabled,
  (paymentProvider, payLaterEnabled, payOverTimeEnabled) => {
    const payLaterDisabled = paymentProvider === PaymentOption.KlarnaPayLater && !payLaterEnabled;
    const payOverTimeDisabled = paymentProvider === PaymentOption.KlarnaPayOverTime && !payOverTimeEnabled;
    return paymentProvider && !payLaterDisabled && !payOverTimeDisabled;
  }
);

export const getPaymentAmountExVat = createSelector(
  state => state.booking.get('saleItems'),
  (saleItems) => {
    if (!saleItems) {
      return 0;
    }
    return saleItems.reduce((total, { amount, vatAmount }) => {
      return total + amount - vatAmount;
    }, 0);
  }
);

export const getVatPctAmounts = createSelector(
  state => state.booking.get('saleItems'),
  (saleItems) => {
    const vatPctAmounts = {};
    saleItems?.forEach(({ vatPct, vatAmount }) => {
      // eslint-disable-next-line no-prototype-builtins
      if (vatPctAmounts.hasOwnProperty(vatPct)) {
        vatPctAmounts[vatPct] += vatAmount;
      } else {
        vatPctAmounts[vatPct] = vatAmount;
      }
    });
    return vatPctAmounts;
  }
);

function getSaleItemTotal(saleItems, filter) {
  return saleItems ? saleItems.filter(filter)
    .reduce((a, b) => a + b.saleAmount, 0) : 0;
}

export const getTotalAmount = createSelector(
  state => state.booking.get('saleItems'),
  saleItems => getSaleItemTotal(saleItems, i => i.itemType !== 'Discount')
);

export const getTotalDiscount = createSelector(
  state => state.booking.get('saleItems'),
  saleItems => getSaleItemTotal(saleItems, i => i.itemType === 'Discount')
);

export const getShowExVatInfo = createSelector(
  getStep, getCurrentIndex,
  (step, index) => {
    const showInSteps = [Step.Resource, Step.Service, Step.Calendar];
    return index === 0 || showInSteps.includes(step);
  }
);
