import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { resetBookingService, setBookingResource } from '../../actions/booking';
import { filterAndGroupResources } from '../../helpers/booking';
import { getPreference } from '../../helpers/settings';
import { web } from '../../helpers/preference-keys';
import { getNextStep, getShowResourceStep, Step } from '../../helpers/nav';
import { enableLocationSelection } from '../../config';
import ResourceGroup from './resource-group';

class Resource extends React.Component {
  componentDidMount() {
    if (this.props.selectedServices) {
      this.props.resetBookingService();
    }
  }

  resourceSelected = (resource) => {
    this.props.setBookingResource(resource || null);
    this.props.history.push(this.props.nextStep);
  };

  render() {
    const { hasSelectedLocation, groupedResources, showResourceStep } = this.props;

    if (enableLocationSelection() && !hasSelectedLocation) {
      return <Redirect to={Step.Location} />;
    }
    if (!showResourceStep) {
      return <Redirect to={Step.Service} />;
    }

    return (
      <>
        <div className="cb-header">
          <h2><FormattedMessage id="resource.heading" /></h2>
        </div>
        <div className="cb-service-list">
          {groupedResources.entrySeq().map(([group, resources]) => {
            return (
              <ResourceGroup
                key={group}
                name={group}
                resources={resources}
                resourceSelected={this.resourceSelected}
                collapseGroups={groupedResources.size > 1}
                showHeader={groupedResources.size > 1}
              />
            );
          })}
          {groupedResources.isEmpty() && (
            <div className="cb-service-empty">
              <FormattedMessage id="resource.noResources" />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { resources, settings, booking } = state;

  return {
    resources,
    hasSelectedLocation: !settings.isEmpty(),
    groupedResources: filterAndGroupResources(state),
    resourceLabel: getPreference(settings, web.resourceLabel),
    showResourceStep: getShowResourceStep(state),
    selectedServices: booking.get('services'),
    nextStep: getNextStep(state, props)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookingResource: (resource) => {
      dispatch(setBookingResource(resource));
    },
    resetBookingService: () => {
      dispatch(resetBookingService());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Resource);
