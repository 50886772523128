import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/da';
import 'intl/locale-data/jsonp/nb';
import 'intl/locale-data/jsonp/sv';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import moment from 'moment';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/sv';

import './styles/standalone.scss';
import './styles/widget.scss';

import enStr from './locale/en';
import daStr from './locale/da';
import nbStr from './locale/nb';
import svStr from './locale/sv';
import config, { initialize } from './config';
import configureStore from './configure-store';
import { initTracking } from './helpers/tracking';
import App from './components/app';

const renderWidget = () => {
  initialize();
  initTracking();
  moment.locale(config.locale);
  moment.relativeTimeThreshold('h', 50);

  const messages = { en: enStr, da: daStr, nb: nbStr, sv: svStr };
  const store = configureStore();

  if (!config.sentryDsn.includes('PLACEHOLDER')) {
    Sentry.init({
      dsn: config.sentryDsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1
    });
  }

  ReactDOM.render(
    <App messages={messages} store={store} />,
    document.getElementById('cliento-booking')
  );
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', renderWidget);
} else {
  renderWidget();
}
