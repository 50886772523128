export function initStandAlone(companyName, resourceName) {
  const context = resourceName || companyName;
  const loader = document.querySelector('#cliento-loader');
  const header = document.querySelector('#cliento-header');
  const booking = document.querySelector('#cliento-booking');
  const heading = document.querySelector('#cliento-header h1');

  if (header) {
    header.removeAttribute('style');
  }
  if (booking) {
    booking.removeAttribute('style');
  }
  if (heading && context) {
    heading.innerText = context;
    document.title = `Tidsbokning ${context} - Cliento`;
  }
  if (loader) {
    loader.parentElement.removeChild(loader);
  }
}

export function addCss(url) {
  const element = document.createElement('link');
  element.rel = 'stylesheet';
  element.type = 'text/css';
  element.href = url;
  document.head.appendChild(element);
}

const primaryColorStyleRules = color => ([`
  .cb-button,
  .cb-button:hover,
  .cb-button:focus,
  .cb-nav-item.active,
  .cb-voucher-label {
    background: ${color} !important;
  }`, `
  .cb-checkbox.checked {
    background: ${color} !important;
    border-color: ${color} !important;
  }`, `
  .cb-day-header.today,
  .cb-slot strong {
    color: ${color} !important;
  }`, `
  .cb-slot:hover,
  .cb-slot:focus {
    border-color: ${color} !important;
  }`
]);

const serviceButtonStickyStyleRule = background => (`
  .cb-service-button-sticky {
    background: ${background};
    background: linear-gradient(0deg, ${background} 70%, transparent 100%);
  }`
);

function getBackgroundColor(element) {
  const transparent = 'rgba(0, 0, 0, 0)';
  const transparentIE11 = 'transparent';
  const background = element
    ? getComputedStyle(element).backgroundColor
    : null;

  if (background === transparent || background === transparentIE11) {
    return getBackgroundColor(element.parentElement);
  }
  return background;
}

export function addStyles(style) {
  const element = document.createElement('style');
  document.head.appendChild(element);

  const { primaryColor } = style || {};
  const styleSheet = element.sheet;

  if (primaryColor) {
    primaryColorStyleRules(primaryColor).forEach((rule) => {
      styleSheet.insertRule(rule);
    });
  }

  const container = document.getElementById('cliento-booking');
  const background = getBackgroundColor(container);
  if (background) {
    styleSheet.insertRule(serviceButtonStickyStyleRule(background));
  }
}
