import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { Router } from 'react-router-dom';
import ReduxIntlProvider from './intl/redux-intl-provider';
import history from '../helpers/history';
import Booking from './booking';

const App = ({ messages, store }) => (
  <Provider store={store}>
    <ReduxIntlProvider messages={messages}>
      <Router history={history}>
        <Route path="*" component={Booking} />
      </Router>
    </ReduxIntlProvider>
  </Provider>
);

export default App;
