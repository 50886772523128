import { fromJS, List } from 'immutable';

import { FETCH_REF_DATA_SUCCESS } from '../actions/ref-data';

export default function services(state = List(), action = null) {
  switch (action.type) {
    case FETCH_REF_DATA_SUCCESS:
      return fromJS(action.services);

    default:
      return state;
  }
}
