import React from 'react';
import ServiceItem from './service-item';
import { ChevronDown, ChevronRight } from '../base/images';

class ServiceGroup extends React.Component {
  state = {
    collapsed: this.props.collapseGroups
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showHeader && !this.props.showHeader && this.state.collapsed) {
      this.setState({ collapsed: false });
    }
  }

  toggleCollapsed = (ev) => {
    ev.preventDefault();
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  render() {
    const { collapsed } = this.state;
    const {
      name, services, showHeader, alwaysShowDescription, multipleServices,
      selectedServices, availableServiceIds, bookableServiceIds
    } = this.props;

    return (
      <div className={collapsed ? 'cb-service-group collapsed' : 'cb-service-group'}>
        {showHeader && (
          <h3 onClick={this.toggleCollapsed}>
            {name}
            {collapsed ? <ChevronRight /> : <ChevronDown />}
          </h3>
        )}
        <div className="cb-service-list">
          {!collapsed && services.map((service) => {
            const serviceId = service.get('serviceId');
            return (
              <ServiceItem
                key={serviceId}
                service={service}
                selected={selectedServices && selectedServices.has(serviceId)}
                available={availableServiceIds.includes(serviceId)}
                bookable={bookableServiceIds.includes(serviceId)}
                multipleServices={multipleServices}
                alwaysShowDescription={alwaysShowDescription}
                onSelect={() => this.props.serviceSelected(service)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default ServiceGroup;
