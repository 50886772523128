import React from 'react';
import { FormattedMessage } from 'react-intl';

const CustomFieldValue = ({ value }) => {
  if (value === true || value === 'true') {
    return <FormattedMessage id="yes" />;
  }
  if (value === false || value === 'false') {
    return <FormattedMessage id="no" />;
  }
  return value;
};

export default CustomFieldValue;
