import React from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { InputType, isCheckbox } from '../../helpers/forms';
import InputField from './input-field';
import CheckboxField from './checkbox-field';

const CustomerField = ({
  intl, type, rows, name, label, hideLabel, extraInfos, helpText, placeholder,
  disabled, optional, validate, autoComplete, parse, children
}) => {
  return (
    <Field
      type={type || InputType.Text}
      component={isCheckbox(type) ? CheckboxField : InputField}
      rows={rows}
      name={name}
      label={hideLabel ? null : label || intl.formatMessage({ id: `customer.${name}` })}
      placeholder={placeholder || (label ? null : intl.formatMessage({ id: `placeholder.${name}` }))}
      autoComplete={autoComplete}
      extraInfos={extraInfos}
      helpText={helpText}
      disabled={disabled}
      optional={optional}
      validate={validate}
      parse={parse}
    >
      {children}
    </Field>
  );
};

export default injectIntl(CustomerField);
