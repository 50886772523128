import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formattedPrice, formattedPriceRange } from '../intl/price';
import { formattedDuration, formattedDurationRange } from '../intl/duration';
import { setBookingResource } from '../../actions/booking';
import { getResourceList, getResourceSelectionEnabled, getSelectedResourceIds } from '../../helpers/booking';
import { getPreference } from '../../helpers/settings';
import { web } from '../../helpers/preference-keys';
import { ChevronDown } from '../base/images';

class ResourceList extends React.Component {
  state = {
    hasSelectedResource: false
  };

  resourceSelected = (ev) => {
    const { resources } = this.props;
    const resourceId = parseInt(ev.target.value, 10);
    const resource = resources.find(r => r.get('id') === resourceId);
    this.props.setBookingResource(resource || null);
    this.setState({ hasSelectedResource: true });
  };

  getResourceText = (resource) => {
    const { showDetails, intl } = this.props;
    const { name, price, minPrice, maxPrice, priceFrom, duration, minDuration, maxDuration } = resource.toObject();

    let resourceText = name;
    if (!showDetails) {
      return resourceText;
    }

    if (price > 0) {
      resourceText += `, ${formattedPrice(intl, price, priceFrom)}`;
    } else if (maxPrice > 0) {
      resourceText += `, ${formattedPriceRange(intl, null, minPrice, maxPrice, priceFrom)}`;
    }
    if (duration > 0) {
      resourceText += ` (${formattedDuration(intl, duration)})`;
    } else if (maxDuration > 0) {
      resourceText += ` (${formattedDurationRange(intl, null, minDuration, maxDuration)})`;
    }
    return resourceText;
  };

  render() {
    const { hasSelectedResource } = this.state;
    const { resourceList, selectedResourceIds, resourceLabel, resourceSelectionEnabled, intl } = this.props;

    if (!resourceSelectionEnabled) {
      return null;
    }

    const availableResources = resourceList.filter(r => r.get('available'));
    const singleResourceId = availableResources.size === 1 ? availableResources.first().get('id') : null;
    const defaultResourceId = hasSelectedResource ? 'ALL' : '';
    const selectedResourceId = selectedResourceIds
      ? resourceList.map(r => r.get('id')).find(id => selectedResourceIds.indexOf(id) !== -1)
      : (singleResourceId || defaultResourceId);

    const defaultResourceLabel = intl.formatMessage({ id: 'summary.resource' }).toLowerCase();
    const selectLabel = { label: resourceLabel ? resourceLabel.toLowerCase() : defaultResourceLabel };
    const selectMessage = intl.formatMessage({ id: 'resource.select' }, selectLabel);
    const showAllMessage = intl.formatMessage({ id: 'resource.showAll' });

    return (
      <div className="cb-resource-list">
        <div className="cb-select">
          <ChevronDown />
          <select value={selectedResourceId} onChange={this.resourceSelected}>
            <option value="" disabled style={{ display: 'none' }}>{selectMessage}</option>
            <option value="ALL">{showAllMessage}</option>
            {resourceList.map((resource) => {
              const resourceId = resource.get('id');
              const disabled = !resource.get('available') || !resource.get('bookable');
              return (
                <option key={resourceId} value={resourceId} disabled={disabled}>
                  {this.getResourceText(resource)}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { resources, settings } = state;

  return {
    resources,
    resourceList: getResourceList(state),
    selectedResourceIds: getSelectedResourceIds(state),
    resourceSelectionEnabled: getResourceSelectionEnabled(state),
    resourceLabel: getPreference(settings, web.resourceLabel)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookingResource: (resource) => {
      dispatch(setBookingResource(resource));
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResourceList));
