import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { fetchInitialData } from '../actions/booking';
import { triggerNavigateEvent } from '../helpers/events';
import { Step, getFirstStep, scrollIntoView } from '../helpers/nav';
import { debugLog } from '../helpers/debug-log';
import { errorLog } from '../helpers/error-log';
import config from '../config';

import Nav from './base/nav';
import Error from './base/error';
import Loading from './base/loading';
import Location from './location/location';
import Resource from './resource/resource';
import Service from './service/service';
import Calendar from './calendar/calendar';
import Customer from './customer/customer';
import Verify from './verify/verify';
import Confirmation from './confirmation';
import Footer from './base/footer';

const StepComponents = {
  [Step.Location]: Location,
  [Step.Resource]: Resource,
  [Step.Service]: Service,
  [Step.Calendar]: Calendar,
  [Step.Customer]: Customer,
  [Step.Verify]: Verify,
  [Step.Confirmation]: Confirmation
};

class Booking extends React.Component {
  state = {
    loaded: false,
    error: null
  };

  componentDidMount() {
    debugLog('Initialized', config);

    try {
      this.props.fetchInitialData().then(
        () => this.setState({ loaded: true }),
        error => this.setState({ error })
      );
    } catch (error) {
      errorLog('Initialize widget failed', error);
      this.setState({ error });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, firstStep } = this.props;

    if (prevProps.location !== location) {
      scrollIntoView();
      triggerNavigateEvent(prevProps.location, location, firstStep);
    }
  }

  render() {
    const { firstStep } = this.props;
    const { loaded, error } = this.state;

    if (error) {
      return <Error error={error} />;
    }
    if (!loaded) {
      return <Loading />;
    }

    return (
      <>
        <Route path="/:step?" component={Nav} />
        <Loading />
        <Switch>
          {Object.keys(StepComponents).map((step) => (
            <Route key={step} exact path={`/:step(${step})`} component={StepComponents[step]} />
          ))}
          <Route path="*" component={StepComponents[firstStep]} />
        </Switch>
        <Route path="/:step?" component={Footer} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firstStep: getFirstStep(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInitialData: () => {
      return dispatch(fetchInitialData());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
