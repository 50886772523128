import { useCallback, useEffect } from 'react';

export const useKeydown = (key, onKeydown) => {
  const handleKeydown = useCallback(({ keyCode }) => {
    if (keyCode === key) {
      onKeydown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);
};

export const usePreventBodyScroll = () => {
  useEffect(() => {
    document.body.classList.add('cb-modal-open');

    return () => {
      document.body.classList.remove('cb-modal-open');
    };
  }, []);
};
