import config from '../config';

let stripe = null;

const elementStyle = {
  base: {
    color: '#555',
    fontSize: '14px',
    fontFamily: 'monospace',
    '::placeholder': {
      color: '#bbb'
    }
  },
  invalid: {
    color: '#e45e59'
  }
};

function initStripe(stripeKey, stripeDestAccountId) {
  if (!stripe) {
    stripe = window.Stripe(stripeKey, {
      stripeAccount: stripeDestAccountId || undefined,
      locale: config.locale || undefined
    });
  }
}

export function appendStripeScript(stripeKey, stripeDestAccountId) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;
    script.onload = () => {
      initStripe(stripeKey, stripeDestAccountId);
      resolve();
    };

    if (!document.querySelector(`script[src="${script.src}"]`)) {
      document.head.appendChild(script);
    } else {
      initStripe(stripeKey, stripeDestAccountId);
      resolve();
    }
  });
}

export function createCardElement({ element, onChange, focusWhenReady }) {
  const elements = stripe.elements();
  const card = elements.create('card', {
    hidePostalCode: true,
    style: elementStyle
  });
  if (focusWhenReady) {
    card.on('ready', () => card.focus());
  }
  if (onChange) {
    card.on('change', onChange);
  }
  card.mount(element);
  return card;
}

export function getStripe() {
  return stripe;
}
