import config from '../config';

function startsWith(string, target) {
  return string && string.indexOf(target) === 0;
}

function PhoneNumber(number, countryCode, nddPrefix, isInternational) {
  this.number = number;
  this.countryCode = countryCode;
  this.nddPrefix = nddPrefix;
  this.isInternational = isInternational;

  this.localized = () => {
    if (this.isInternational) {
      return this.e164formatted();
    }
    return this.withNDD();
  };

  this.withNDD = () => {
    return this.number ? this.nddPrefix + this.number : this.number;
  };

  this.withCountryCode = () => {
    return this.number ? this.countryCode + this.number : this.number;
  };

  this.e164formatted = () => {
    return `+${this.withCountryCode()}`;
  };
}

const PhoneNumberParser = {
  initialize(countryCode, nddPrefix, iddPrefix) {
    this.countryCode = countryCode;
    this.nddPrefix = nddPrefix;
    this.iddPrefix = iddPrefix;
  },

  parseNumber(number) {
    let normalized = this.normalize(number);

    if (this.isInternational(normalized)) {
      if (startsWith(normalized, this.iddPrefix)) {
        return new PhoneNumber(normalized.substring(2), '', '', true);
      }
      return new PhoneNumber(normalized, '', '', true);
    }
    if (startsWith(normalized, this.iddPrefix)) {
      normalized = normalized.substring(this.iddPrefix.length);
    }
    if (startsWith(normalized, this.nddPrefix)) {
      normalized = normalized.substring(this.nddPrefix.length);
    }
    if (startsWith(normalized, this.countryCode)) {
      normalized = normalized.substring(this.countryCode.length);
    }
    return new PhoneNumber(normalized, this.countryCode, this.nddPrefix, false);
  },

  isInternational(number) {
    if (!startsWith(number, this.iddPrefix) && !startsWith(number, this.nddPrefix) && !startsWith(number, this.countryCode)) {
      return true;
    }
    return startsWith(number, this.iddPrefix) && !startsWith(number, this.iddPrefix + this.countryCode);
  },

  normalize(number) {
    return number.replace(/\s/g, '')
      .replace(/\+/g, this.iddPrefix)
      .replace(/\D/g, '');
  }
};

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return null;
  }
  return PhoneNumberParser.parseNumber(phoneNumber).localized();
}

export function formatPhoneNumberE164(phoneNumber) {
  if (!phoneNumber) {
    return null;
  }
  return PhoneNumberParser.parseNumber(phoneNumber).e164formatted();
}

export function initPhoneNumber(countryCode, nddPrefix, iddPrefix) {
  PhoneNumberParser.initialize(countryCode, nddPrefix, iddPrefix);

  if (config.customer && config.customer.phone) {
    config.customer.phone = formatPhoneNumber(config.customer.phone);
  }
}

export function isValidPhoneNumber(phoneNumber) {
  return /^(0|\+)[- 0-9]{7,15}$/.test(phoneNumber);
}

export function showPhoneNumberHint(phoneNumber) {
  return !/^(0|\+)/.test(phoneNumber);
}
