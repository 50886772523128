import React from 'react';
import classNames from 'classnames';
import { ChevronRight } from '../base/images';
import { isSet } from '../../helpers/strings';
import Price from '../intl/price';
import Duration from '../intl/duration';
import config from '../../config';

class ResourceItem extends React.Component {
  render() {
    const { resource, onSelect } = this.props;
    const {
      name, minDuration, maxDuration, minPrice, maxPrice,
      priceFrom, webShowPrice, webShowDuration
    } = resource.toObject();

    const showDuration = webShowDuration && config.showResourceDuration && isSet(minDuration);
    const showPrice = webShowPrice && config.showResourcePrice && isSet(minPrice);

    const className = classNames({
      'cb-service-item': true,
      available: true,
      bookable: true
    });

    return (
      <div className={className} onClick={onSelect}>
        <button type="button" className="cb-nav-item active">
          <ChevronRight />
        </button>
        <strong>{name}</strong>
        {showDuration && <span><Duration minDuration={minDuration} maxDuration={maxDuration} /></span>}
        {showPrice && <span><Price minPrice={minPrice} maxPrice={maxPrice} priceFrom={priceFrom} /></span>}
      </div>
    );
  }
}

export default ResourceItem;
