import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isDateInput, isSelect, isTextInput } from '../../helpers/forms';
import DateInput from './date-input';

const InputField = (props) => {
  const {
    input, label, placeholder, extraInfos, helpText, type, rows, optional, children,
    meta: { touched, error, submitError }, ...rest
  } = props;
  const id = `cb-${input.name}`;
  const select = isSelect(input.type) && children;
  const textarea = isTextInput(input.type) && rows;
  const date = isDateInput(input.type);
  const showLabel = label || error || submitError;
  const inputHelp = touched && error && error.hint ? error.hint : helpText;

  return (
    <div className="cb-input-group">
      {showLabel && (
        <label className="cb-label" htmlFor={id}>
          <span>{label}</span>
          {optional && <span className="cb-label-optional"><FormattedMessage id="optional" /></span>}
          {touched && submitError && <span className="cb-label-error">{submitError}</span>}
          {touched && error && <span className="cb-label-error">{error.label}</span>}
        </label>
      )}
      {extraInfos && extraInfos.map((extraInfo, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <span key={index} className="cb-note-info">{extraInfo}</span>;
      })}
      {select && (
        <select className="cb-input" id={id} placeholder={placeholder} {...input} {...rest}>
          {children}
        </select>
      )}
      {textarea && (
        <textarea className="cb-input" rows={rows} id={id} placeholder={placeholder} {...input} {...rest} />
      )}
      {date && (
        <DateInput input={input} placeholder={placeholder} />
      )}
      {!select && !textarea && !date && (
        <input className="cb-input" type={type} id={id} placeholder={placeholder} {...input} {...rest} />
      )}
      {inputHelp && <span className="cb-input-help">{inputHelp}</span>}
    </div>
  );
};

export default InputField;
