import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const PrivacyPolicy = ({ stableId }) => (
  <div className="cb-privacy">
    <a href={`https://cliento.com/gdpr/${stableId}`} target="_blank">
      <FormattedMessage id="footer.privacy" />
    </a>
  </div>
);

PrivacyPolicy.propTypes = {
  stableId: PropTypes.string.isRequired
};

export default PrivacyPolicy;
