import config from '../config';
import { debugLog } from './debug-log';

const hasMetaPixel = () => typeof window?.fbq === 'function';
const hasDataLayer = () => typeof window?.dataLayer?.push === 'function';

const trackingEnabled = (type) => (Array.isArray(config.enableTracking)
  && config.enableTracking.includes(type))
  || config.enableTracking === type;

function trackMetaEvent(event, data) {
  if (trackingEnabled('Meta') && hasMetaPixel()) {
    debugLog(`Meta Pixel: ${event}`, data);
    window.fbq('track', event, data);
  }
}

function trackGtmDataLayer(event, data) {
  if (trackingEnabled('GTM') && hasDataLayer()) {
    debugLog(`Google Tag Manager: ${event}`, data);
    window.dataLayer.push({ event, ...data });
  }
}

export function initTracking() {
  if (trackingEnabled('GTM') && hasDataLayer()) {
    debugLog('Google Tag Manager: Tracking enabled');
  } else {
    debugLog('Google Tag Manager: Tracking disabled', {
      enabled: trackingEnabled('GTM'),
      dataLayer: hasDataLayer()
    });
  }

  if (trackingEnabled('Meta') && hasMetaPixel()) {
    debugLog('Meta Pixel: Tracking enabled');
  } else {
    debugLog('Meta Pixel: Tracking disabled', {
      enabled: trackingEnabled('Meta'),
      hasPixel: hasMetaPixel()
    });
  }
}

export function trackPageView() {
  trackGtmDataLayer('pageview');
  trackMetaEvent('PageView');
}

export function trackBookingCompleted(booking) {
  const { bookingRef, price, services, location } = booking;
  const serviceNames = services.join(', ');

  trackGtmDataLayer('purchase', {
    ecommerce: {
      items: [{
        item_id: bookingRef,
        item_name: serviceNames,
        price
      }],
      transaction_id: bookingRef,
      affiliation: location,
      value: price,
      currency: config.currency
    }
  });

  trackMetaEvent('Purchase', {
    content_name: serviceNames,
    value: price,
    currency: config.currency
  });
}
