import React from 'react';
import { FormattedMessage } from 'react-intl';

const CalendarProgress = ({ error }) => {
  return (
    <>
      <div className="cb-header">
        {/*
          This step is shown as an intermediate step between service and customer.
          Keep the service heading to reduce flickering.
        */}
        <h2><FormattedMessage id="service.heading" /></h2>
      </div>
      <div className="cb-calendar-progress">
        {error ?
          <FormattedMessage id="calendar.notAvailable" /> :
          <FormattedMessage id="calendar.progress" />}
      </div>
    </>
  );
};

export default CalendarProgress;
