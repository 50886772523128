import { fromJS, Map } from 'immutable';

import { FETCH_SETTINGS_SUCCESS } from '../actions/settings';

export default function locations(state = Map(), action = null) {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return state.set(action.settings.stableId, fromJS(action.settings));

    default:
      return state;
  }
}
