import { createSelector } from 'reselect';
import { getCurrentMappedServices, getCurrentResource } from './booking';
import { web } from './preference-keys';

function getValueIn(settings, parent, name) {
  if (!settings) {
    return null;
  }
  return settings.getIn([parent, name]);
}

export function getPreference(settings, name) {
  return getValueIn(settings, 'prefs', name);
}

export function getPreferences(settings, services, name) {
  const values = [getPreference(settings, name)];
  if (services && !services.isEmpty()) {
    services.forEach((service) => {
      const value = getPreference(service, name);
      if (values.indexOf(value) === -1) {
        values.push(value);
      }
    });
  }
  return values;
}

export function getFeature(settings, name) {
  return getValueIn(settings, 'features', name);
}

export const getMergedWebSettings = createSelector(
  state => state.settings,
  state => getCurrentResource(state),
  state => getCurrentMappedServices(state),
  (settings, resource, services) => {
    const webSettings = {};
    Object.keys(web).forEach((key) => {
      const webKey = `web_${key}`;
      webSettings[key] = getPreference(settings, webKey);

      if (resource) {
        const value = getPreference(resource, webKey);
        if (typeof value !== 'undefined') {
          webSettings[key] = value;
        }
      }

      if (services) {
        services.forEach((service) => {
          const value = getPreference(service, webKey);
          if (typeof value !== 'undefined') {
            webSettings[key] = value;
          }
        });
      }
    });
    return webSettings;
  }
);

export const getBookingAddress = createSelector(
  state => getCurrentMappedServices(state),
  state => getMergedWebSettings(state),
  (services, webSettings) => {
    const serviceAddress = services && services
      .map(s => s.getIn(['attributes', 'address']))
      .filter(s => s)
      .first();

    const { addressLine1, area } = webSettings;
    const prefsAddress = [addressLine1, area].filter(s => s).join(', ');

    return serviceAddress || prefsAddress;
  }
);
