import React from 'react';
import { connect } from 'react-redux';
import { fetchBookingStatus } from '../../actions/booking';

class BookingStatus extends React.Component {
  componentDidMount() {
    this.initTimer();
  }

  componentWillUnmount() {
    this.resetTimer();
  }

  initTimer = () => {
    this.resetTimer();
    this.interval = setInterval(() => {
      this.props.fetchBookingStatus()
        .catch(() => this.resetTimer());
    }, 5000);
  };

  resetTimer = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch, { paymentProvider }) => {
  return {
    fetchBookingStatus: () => dispatch(fetchBookingStatus(paymentProvider))
  };
};

export default connect(null, mapDispatchToProps)(BookingStatus);
