import { getOrCreateKlarnaSession } from '../actions/payment';
import { errorLog } from './error-log';
import config from '../config';

export function getWidgetData({ paymentAmountIncVat, saleItems }) {
  const { primaryColor } = config.style || {};
  return {
    options: {
      color_details: primaryColor || '#F85FA4',
      color_border_selected: primaryColor || '#F85FA4'
    },
    order_amount: paymentAmountIncVat * 100,
    order_lines: saleItems.map(({
      itemType, quantity, amount, discountAmount, description
    }) => {
      const isDiscount = itemType === 'Discount';
      const itemAmount = isDiscount ? discountAmount : amount;
      return {
        quantity,
        total_amount: itemAmount * quantity * 100,
        name: description,
        type: isDiscount ? 'discount' : 'physical',
        unit_price: itemAmount * 100
      };
    })
  };
}

function initializeKlarna({ clientToken }, retryOnError) {
  return (dispatch) => {
    try {
      window.Klarna.Payments.init({
        client_token: clientToken
      });
    } catch (error) {
      errorLog('Error initializing Klarna widget', error);

      if (retryOnError) {
        // eslint-disable-next-line no-use-before-define
        dispatch(getKlarnaSessionAndInitialize());
      }
    }
  };
}

function getKlarnaSessionAndInitialize(retryOnError) {
  return (dispatch) => {
    return dispatch(getOrCreateKlarnaSession())
      .then(session => {
        if (session) {
          dispatch(initializeKlarna(session), retryOnError);
        }
      });
  };
}

export function appendKlarnaScript() {
  return (dispatch) => {
    const script = document.createElement('script');
    script.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
    script.onload = () => {
      dispatch(getKlarnaSessionAndInitialize(true));
    };

    if (!document.querySelector(`script[src="${script.src}"]`)) {
      document.head.appendChild(script);
    } else {
      dispatch(getKlarnaSessionAndInitialize(true));
    }
  };
}
