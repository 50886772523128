import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { resetBooking } from '../actions/booking';
import { CheckBoxCircle, ChevronRight } from './base/images';
import { formatPhoneNumber } from '../helpers/phone-number';
import { getFirstStep } from '../helpers/nav';
import { getMergedWebSettings, getPreference } from '../helpers/settings';
import { sms } from '../helpers/preference-keys';
import Summary from './summary';
import Markdown from './base/markdown';

class Confirmation extends React.Component {
  componentWillUnmount() {
    this.props.resetBooking();
  }

  render() {
    const {
      slot, customer, emailConfirmSent, allowSmsNow, smsReminderEnabled, webSettings,
      smsReminderWithin, postConfirmBtnUrl, firstStep
    } = this.props;
    const { bookingExtraInfo, showExtraInfoOnConfirmPage } = webSettings;
    const showExtraInfo = showExtraInfoOnConfirmPage && bookingExtraInfo;
    const { phone, email } = customer || {};

    if (!slot) {
      return <Redirect to={firstStep} />;
    }

    return (
      <>
        <div className="cb-header">
          <div className="cb-confirmation-check">
            <CheckBoxCircle />
          </div>
          <h2><FormattedMessage id="confirmation.heading" /></h2>
        </div>
        {postConfirmBtnUrl && (
          <div className="cb-confirmation-info">
            <p>
              <FormattedMessage id="confirmation.postInfo" />
            </p>
            <a href={postConfirmBtnUrl} className="cb-button">
              <FormattedMessage id="confirmation.postButton" />
              <ChevronRight />
            </a>
          </div>
        )}
        <Summary showCustomer />
        {emailConfirmSent && email && (
          <p>
            <FormattedMessage
              id="confirmation.email"
              values={{ email: <strong>{email}</strong> }}
            />
          </p>
        )}
        {allowSmsNow && smsReminderEnabled && (
          <p>
            <FormattedMessage
              id="confirmation.reminder"
              values={{
                phone: <strong>{formatPhoneNumber(phone)}</strong>,
                interval: smsReminderWithin
              }}
            />
          </p>
        )}
        {showExtraInfo && <Markdown className="cb-extra-info" source={bookingExtraInfo} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { booking, settings } = state;
  const attributes = booking.get('attributes');

  return {
    slot: booking.get('slot'),
    customer: booking.get('customer'),
    allowSmsNow: getPreference(settings, 'allowSmsNow'),
    smsReminderEnabled: booking.get('smsReminderEnabled'),
    emailConfirmSent: booking.get('emailConfirmSent'),
    smsReminderWithin: getPreference(settings, sms.reminderWithin),
    postConfirmBtnUrl: attributes && attributes.postConfirmBtnUrl,
    webSettings: getMergedWebSettings(state),
    firstStep: getFirstStep(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetBooking: () => dispatch(resetBooking())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
