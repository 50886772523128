import React from 'react';
import ResourceItem from './resource-item';
import { ChevronDown, ChevronRight } from '../base/images';

class ResourceGroup extends React.Component {
  state = {
    collapsed: this.props.collapseGroups
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showHeader && !this.props.showHeader && this.state.collapsed) {
      this.setState({ collapsed: false });
    }
  }

  toggleCollapsed = (ev) => {
    ev.preventDefault();
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  render() {
    const { collapsed } = this.state;
    const { name, showHeader, resources, resourceSelected } = this.props;

    return (
      <div className={collapsed ? 'cb-service-group collapsed' : 'cb-service-group'}>
        {showHeader && (
          <h3 onClick={this.toggleCollapsed}>
            {name}
            {collapsed ? <ChevronRight /> : <ChevronDown />}
          </h3>
        )}
        <div className="cb-service-list">
          {!collapsed && resources.map((resource) => {
            return (
              <ResourceItem
                key={resource.get('id')}
                resource={resource}
                onSelect={() => resourceSelected(resource)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default ResourceGroup;
