import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { fetchAndSelectFirstSlotWithDelay, fetchSlots, resetSlots } from '../../actions/slots';
import {
  getFilteredResources, getMappedResources, getSelectedResourceIds, getResourceById, filterSlots,
  getResourceSelectionEnabled, getSingleAvailableService
} from '../../helpers/booking';
import { addBookingService, setBookingSlot } from '../../actions/booking';
import { getNextStep, getFirstStep, getShowServiceStep } from '../../helpers/nav';
import { getMergedWebSettings, getPreference } from '../../helpers/settings';
import { web } from '../../helpers/preference-keys';
import config from '../../config';
import ResourceList from '../resource/resource-list';
import { ChevronRight } from '../base/images';
import CalendarNav from './calendar-nav';
import CalendarSlot from './calendar-slot';
import CalendarProgress from './calendar-progress';
import Error from '../base/error';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    const minDate = moment.max(moment().startOf('day'), moment(config.minDate));
    const maxDate = config.maxDate ? moment(config.maxDate) : null;
    const week = props.fromDate ? moment(props.fromDate) : minDate;

    this.state = {
      week,
      today: moment(),
      minDate,
      maxDate,
      error: null
    };
  }

  componentDidMount() {
    const { days, services, showServiceStep, singleAvailableService } = this.props;
    const hasSelectedServices = services && !services.isEmpty();

    if (days) {
      this.props.resetSlots();
    }
    if (!showServiceStep && singleAvailableService && !hasSelectedServices) {
      this.props.addBookingService(singleAvailableService);
    }
    this.fetchSlots();
  }

  componentDidUpdate(prevProps) {
    const {
      selectedResourceIds, services, days, nextAvailable, isWeekEmpty, gotoNextAvailableTime
    } = this.props;
    const resourceChanged = prevProps.selectedResourceIds !== selectedResourceIds;
    const servicesChanged = prevProps.services !== services;
    const isFirstLoad = !prevProps.days && days;

    if (resourceChanged || servicesChanged) {
      this.fetchSlots();
    }
    if (isFirstLoad && isWeekEmpty && nextAvailable && gotoNextAvailableTime) {
      this.nextAvailable();
    }
  }

  navPrev = (ev) => {
    ev.preventDefault();
    this.setState(({ week }) => ({ week: moment(week).subtract(1, 'w') }), this.fetchSlots);
  };

  navNext = (ev) => {
    ev.preventDefault();
    this.setState(({ week }) => ({ week: moment(week).add(1, 'w') }), this.fetchSlots);
  };

  nextAvailable = (ev) => {
    ev?.preventDefault();
    const { nextAvailable } = this.props;
    this.setState({ week: moment(nextAvailable).startOf('isoWeek') }, this.fetchSlots);
  };

  fetchSlots = (props) => {
    const { week } = this.state;
    const { services, selectedResourceIds, webSettings } = props || this.props;
    const { autoSelectFirstAvailableSlot } = webSettings;

    if (services && !services.isEmpty()) {
      const serviceIds = services.map(s => s.get('serviceId'));
      const fromDate = moment(week).startOf('isoWeek');
      const toDate = moment(week).endOf('isoWeek');

      if (autoSelectFirstAvailableSlot) {
        this.props.fetchAndSelectFirstSlotWithDelay(serviceIds, selectedResourceIds)
          .then(() => this.props.history.push(this.props.nextStep), error => this.setState({ error }));
      } else {
        this.props.fetchSlots(serviceIds, selectedResourceIds, fromDate, toDate)
          .then(() => this.setState({ error: null }), error => this.setState({ error }));
      }
    }
  };

  slotClick = (ev, slot) => {
    ev.preventDefault();
    this.props.setBookingSlot(slot);
    this.props.history.push(this.props.nextStep);
  };

  render() {
    const { week, today, minDate, maxDate, error } = this.state;
    const {
      services, mappedResources, selectedResourceIds, autoResourceSelection, showAllAvailableSlots,
      days, isWeekEmpty, nextAvailable, bookingMaxDaysInAdvance, firstStep, resourceSelectionEnabled,
      webSettings: { autoSelectFirstAvailableSlot }, showServiceStep
    } = this.props;
    const singleResource = mappedResources.count(r => r.get('available')) === 1;
    const showExtended = resourceSelectionEnabled && !selectedResourceIds && !singleResource;
    const hasSelectedServices = services && !services.isEmpty();

    if (!hasSelectedServices && showServiceStep) {
      return <Redirect to={firstStep} />;
    }
    if (autoSelectFirstAvailableSlot) {
      return <CalendarProgress error={error} />;
    }

    return (
      <>
        <div className="cb-header">
          <h2><FormattedMessage id="calendar.heading" /></h2>
        </div>
        <ResourceList showDetails />
        <CalendarNav
          week={week}
          minDate={minDate}
          maxDate={maxDate}
          navPrev={this.navPrev}
          navNext={this.navNext}
        />
        {error ? (
          <Error error={error} />
        ) : (
          <div className="cb-calendar-days">
            {days && days.entrySeq().map(([date, slots]) => {
              const isToday = date.isSame(today, 'day');
              const isBeforeMin = date.isBefore(minDate, 'day');
              const isAfterMax = maxDate && date.isAfter(maxDate, 'day');
              const disabled = isBeforeMin || isAfterMax;
              const className = classNames({
                'cb-calendar-day': true,
                today: date.isSame(today, 'day'),
                disabled
              });
              const filteredSlots = filterSlots(slots.toJS(), autoResourceSelection, showAllAvailableSlots);
              return (
                <div className={className} key={date.valueOf()}>
                  <div className={isToday ? 'cb-day-header today' : 'cb-day-header'}>
                    {isToday ?
                      <FormattedMessage id="calendar.today" /> :
                      date.format('ddd').replace('.', '')}<br />
                    <span className="cb-date">{date.format('D')}</span>
                  </div>
                  <div className="cb-calendar-items">
                    {!disabled && filteredSlots.length > 0 ? filteredSlots.map(slot => (
                      <CalendarSlot
                        {...slot}
                        key={slot.key}
                        onSelect={ev => this.slotClick(ev, slot)}
                        resource={getResourceById(mappedResources, slot.resourceId)}
                        showExtended={showExtended}
                      />
                    )) : (
                      <p className="cb-calendar-day-empty">
                        {!isWeekEmpty && <FormattedMessage id="calendar.noSlots" />}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
            {isWeekEmpty && (
              <div className="cb-calendar-week-empty">
                {nextAvailable ? (
                  <p>
                    <FormattedMessage id="calendar.noneThisWeek" /><br />
                    <FormattedMessage
                      id="calendar.nextAvailable"
                      values={{ nextAvailable: moment(nextAvailable).format('dddd D MMMM') }}
                    /><br />
                    <a href="#" onClick={this.nextAvailable}>
                      <FormattedMessage id="calendar.showAvailable" />
                      <ChevronRight />
                    </a>
                  </p>
                ) : (
                  <p>
                    <FormattedMessage
                      id="calendar.noneAtAll"
                      values={{ duration: moment.duration(bookingMaxDaysInAdvance, 'day').humanize() }}
                    />
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { booking, settings, slots } = state;
  const services = booking.get('services');
  const days = slots.get('days');

  return {
    resources: getFilteredResources(state),
    mappedResources: getMappedResources(state),
    selectedResourceIds: getSelectedResourceIds(state),
    resourceSelectionEnabled: getResourceSelectionEnabled(state),
    autoResourceSelection: getPreference(settings, web.autoResourceSelection),
    showAllAvailableSlots: getPreference(settings, web.showAllAvailableSlots),
    bookingMaxDaysInAdvance: getPreference(settings, web.bookingMaxDaysInAdvance),
    gotoNextAvailableTime: getPreference(settings, web.gotoNextAvailableTime),
    singleAvailableService: getSingleAvailableService(state),
    showServiceStep: getShowServiceStep(state),
    webSettings: getMergedWebSettings(state),
    fromDate: booking.get('fromDate'),
    services,
    days,
    isWeekEmpty: days && days.every(d => d.isEmpty()),
    nextAvailable: slots.get('nextAvailable'),
    firstStep: getFirstStep(state),
    nextStep: getNextStep(state, props)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSlots: (serviceIds, resourceIds, fromDate, toDate) => {
      return dispatch(fetchSlots(serviceIds, resourceIds, fromDate, toDate));
    },
    fetchAndSelectFirstSlotWithDelay: (serviceIds, resourceIds) => {
      return dispatch(fetchAndSelectFirstSlotWithDelay(serviceIds, resourceIds));
    },
    resetSlots: () => {
      dispatch(resetSlots());
    },
    setBookingSlot: (slot) => {
      dispatch(setBookingSlot(slot));
    },
    addBookingService: (service) => {
      dispatch(addBookingService(service));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
