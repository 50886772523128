import React from 'react';
import { getPreference } from '../../helpers/settings';
import { web } from '../../helpers/preference-keys';
import { ChevronRight } from '../base/images';

const LocationItem = ({ location, onSelect }) => {
  const companyName = getPreference(location, web.companyName);

  return (
    <div className="cb-location" onClick={onSelect}>
      <button type="button" className="cb-nav-item active">
        <ChevronRight />
      </button>
      <strong>{companyName}</strong>
    </div>
  );
};

export default LocationItem;
