import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { required, phone, composeValidators } from '../../helpers/validation';
import {
  getIsValidPaymentProvider, getKlarnaPayLaterEnabled, getKlarnaPayOverTimeEnabled,
  KlarnaMethod, PaymentOption
} from '../../helpers/payment';
import { errorLog } from '../../helpers/error-log';
import { toggleCheckboxWithLink } from '../../helpers/forms';
import { setBookingExtraTermsAccepted } from '../../actions/booking';
import InputField from '../base/input-field';
import StripeForm from '../payment/stripe-form';
import KlarnaForm from '../payment/klarna-form';
import Price from '../intl/price';
import Markdown from '../base/markdown';
import { ChevronRight } from '../base/images';
import Error from '../base/error';
import Radio from '../base/radio';
import CheckBox from '../base/checkbox';
import config from '../../config';

const VerifyForm = ({
  onSubmit, customer, useSmsVerification, paymentAmountIncVat, paymentOptions, webSettings,
  confirmError, paymentRequired, paymentTerms, paymentProvider, setPaymentProvider, intl
}) => {
  const dispatch = useDispatch();
  const stripeSelected = paymentProvider === PaymentOption.Stripe;
  const swishSelected = paymentProvider === PaymentOption.Swish;
  const payLaterSelected = paymentProvider === PaymentOption.KlarnaPayLater;
  const payOverTimeSelected = paymentProvider === PaymentOption.KlarnaPayOverTime;
  const payLaterEnabled = useSelector(getKlarnaPayLaterEnabled);
  const payOverTimeEnabled = useSelector(getKlarnaPayOverTimeEnabled);
  const isValidPaymentProvider = useSelector(getIsValidPaymentProvider);
  const extraTermsAccepted = useSelector(state => state.booking.get('extraTermsAccepted'));
  const initialValues = { phoneNumber: customer.phone };
  let stripeForm = null;
  let payLaterForm = null;
  let payOverTimeForm = null;

  const { showTCBox, showExtraTCBox, extraTCBoxLabel, showExtraTCBoxInVerifyStep } = webSettings;
  const paymentMethodNotSelected = paymentRequired && !isValidPaymentProvider;
  const extraTermsNotAccepted = showTCBox && showExtraTCBox && showExtraTCBoxInVerifyStep && !extraTermsAccepted;
  const submitDisabled = paymentMethodNotSelected || extraTermsNotAccepted;

  const toggleExtraTermsAccepted = (ev) => {
    toggleCheckboxWithLink(ev,
      (termsAccepted) => dispatch(setBookingExtraTermsAccepted(termsAccepted)),
      extraTermsAccepted);
  };

  const preSubmit = (formData) => {
    const data = {
      paymentProvider,
      ...formData
    };
    if (stripeSelected && stripeForm) {
      return stripeForm.onSubmit()
        .then(() => onSubmit(data), (error) => {
          errorLog('Stripe form submit error', error);
        });
    }
    if (payLaterSelected && payLaterForm) {
      return payLaterForm.onSubmit();
    }
    if (payOverTimeSelected && payOverTimeForm) {
      return payOverTimeForm.onSubmit();
    }
    return onSubmit(data);
  };

  return (
    <Form onSubmit={preSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting }) => (
        <form className="cb-form" onSubmit={handleSubmit}>
          {confirmError && <Error error={confirmError} useSmsVerification={useSmsVerification} />}
          {useSmsVerification && (
            <Field
              type="tel"
              name="code"
              label="&nbsp;"
              component={InputField}
              autoComplete="off"
              placeholder="PIN"
              validate={required}
            />
          )}
          {paymentRequired && !paymentAmountIncVat && (
            <div className="cb-payment-option">
              <h3>
                <Radio checked />
                <FormattedMessage id="payment.providerNone" />
              </h3>
              <p><FormattedMessage id="payment.providerNoneInfo" /></p>
            </div>
          )}
          {paymentRequired && paymentAmountIncVat && paymentOptions.length === 0 ? (
            <Error error={{ message: intl.formatMessage({ id: 'error.paymentRequiredNA' }) }} />
          ) : null}
          {paymentRequired && paymentAmountIncVat && paymentOptions.includes(PaymentOption.Swish) ? (
            <div className="cb-payment-option">
              <h3 onClick={() => setPaymentProvider(PaymentOption.Swish)}>
                <img src={`${config.images}/swish.svg`} alt="Swish" className="cb-swish" />
                <Radio checked={swishSelected} />
                <FormattedMessage id="payment.providerSwish" />
              </h3>
              {swishSelected && (
                <Field
                  type="tel"
                  name="phoneNumber"
                  label={<FormattedMessage id="payment.phoneNumber" />}
                  component={InputField}
                  autoComplete="off"
                  placeholder=""
                  validate={composeValidators(required, phone)}
                />
              )}
            </div>
          ) : null}
          {paymentRequired && paymentAmountIncVat && paymentOptions.includes(PaymentOption.Stripe) ? (
            <div className="cb-payment-option">
              <h3 onClick={() => setPaymentProvider(PaymentOption.Stripe)}>
                <img src={`${config.images}/amex.svg`} alt="American Express" className="cb-amex" />
                <img src={`${config.images}/visa.svg`} alt="Visa" className="cb-visa" />
                <img src={`${config.images}/mastercard.svg`} alt="Mastercard" className="cb-mastercard" />
                <Radio checked={stripeSelected} />
                <FormattedMessage id="payment.providerCard" />
              </h3>
              {stripeSelected && (
                <StripeForm ref={(ref) => { stripeForm = ref; }} />
              )}
            </div>
          ) : null}
          {paymentRequired && paymentAmountIncVat && paymentOptions.includes(PaymentOption.KlarnaPayLater) ? (
            <div className="cb-payment-option">
              <h3 onClick={() => setPaymentProvider(PaymentOption.KlarnaPayLater)}>
                <img src={`${config.images}/klarna-pink.svg`} alt="Klarna" className="cb-klarna" />
                <Radio checked={payLaterSelected} />
                <FormattedMessage id="payment.klarnaLater" />
              </h3>
              <KlarnaForm
                onConfirm={onSubmit}
                category={KlarnaMethod.PayLater}
                selected={payLaterSelected}
                enabled={payLaterEnabled}
                amount={paymentAmountIncVat}
                ref={(ref) => { payLaterForm = ref; }}
              />
            </div>
          ) : null}
          {paymentRequired && paymentAmountIncVat && paymentOptions.includes(PaymentOption.KlarnaPayOverTime) ? (
            <div className="cb-payment-option">
              <h3 onClick={() => setPaymentProvider(PaymentOption.KlarnaPayOverTime)}>
                <img src={`${config.images}/klarna-pink.svg`} alt="Klarna" className="cb-klarna" />
                <Radio checked={payOverTimeSelected} />
                <FormattedMessage id="payment.klarnaOverTime" />
              </h3>
              <KlarnaForm
                onConfirm={onSubmit}
                category={KlarnaMethod.PayOverTime}
                selected={payOverTimeSelected}
                enabled={payOverTimeEnabled}
                amount={paymentAmountIncVat}
                ref={(ref) => { payOverTimeForm = ref; }}
              />
            </div>
          ) : null}
          {showTCBox && showExtraTCBox && extraTCBoxLabel && showExtraTCBoxInVerifyStep && (
            <section>
              <div className="cb-checkbox-container cb-accept" onClick={toggleExtraTermsAccepted}>
                <CheckBox checked={extraTermsAccepted} />
                <label>
                  <Markdown source={extraTCBoxLabel} />
                </label>
              </div>
            </section>
          )}
          <br />
          <button type="submit" className="cb-button" disabled={submitting || submitDisabled}>
            {paymentAmountIncVat ? (
              <FormattedMessage
                id="verify.confirmAndPay"
                values={{ amount: <Price price={paymentAmountIncVat} fractionDigits={2} /> }}
              />
            ) : (
              <FormattedMessage id="verify.confirmBooking" />
            )}
            <ChevronRight />
          </button>
          {paymentRequired && paymentTerms && (
            <Markdown source={paymentTerms} className="cb-payment-terms" />
          )}
        </form>
      )}
    </Form>
  );
};

export default injectIntl(VerifyForm);
