import { createSelector } from 'reselect';
import { enableLocationSelection } from '../config';
import { getMergedWebSettings, getPreference } from './settings';
import { getSingleAvailableService } from './booking';
import { web } from './preference-keys';

export const Step = {
  Location: 'location',
  Resource: 'resource',
  Service: 'service',
  Calendar: 'calendar',
  Customer: 'customer',
  Verify: 'verify',
  Confirmation: 'confirmation'
};

export function getDirection(prevStep, nextStep) {
  const steps = Object.values(Step);
  const nextIndex = steps.indexOf(nextStep);
  const prevIndex = steps.indexOf(prevStep);
  return nextIndex > prevIndex ? 'forward' : 'backward';
}

export function getStepFromLocation(location) {
  return location?.pathname?.replace('/', '');
}

export const getStep = (state, props) => props.match.params.step;

export const getHideNav = createSelector(
  getStep,
  (step) => {
    return step === Step.Location || step === Step.Confirmation
      || (!step && enableLocationSelection());
  }
);

export const getShowResourceStep = createSelector(
  state => getPreference(state.settings, web.showResourceStep),
  state => state.settings.get('resourceId'),
  (showResourceStep, vipResourceId) => {
    return showResourceStep && !vipResourceId;
  }
);

export const getShowServiceStep = createSelector(
  state => getPreference(state.settings, web.skipServiceStep),
  getSingleAvailableService,
  (skipServiceStep, singleAvailableService) => {
    return !skipServiceStep || !singleAvailableService;
  }
);

export const getSteps = createSelector(
  getShowResourceStep,
  getShowServiceStep,
  (showResourceStep, showServiceStep) => {
    const steps = [];
    if (enableLocationSelection()) {
      steps.push(Step.Location);
    }
    if (showResourceStep) {
      steps.push(Step.Resource);
    }
    if (showServiceStep) {
      steps.push(Step.Service);
    }
    return steps.concat([
      Step.Calendar,
      Step.Customer,
      Step.Verify
    ]);
  }
);

export const getFirstStep = createSelector(
  getSteps,
  (steps) => {
    return steps && steps[0];
  }
);

export const getCurrentIndex = createSelector(
  getSteps, getStep,
  (steps, step) => Math.max(steps.indexOf(step), 0)
);

export const getPreviousStep = createSelector(
  getSteps,
  getCurrentIndex,
  getMergedWebSettings,
  (steps, index, { autoSelectFirstAvailableSlot }) => {
    const previousStep = index > 0 ? steps[index - 1] : null;
    const skipCalendarStep = previousStep === Step.Calendar && autoSelectFirstAvailableSlot;
    return skipCalendarStep ? Step.Service : previousStep;
  }
);

export const getNextStep = createSelector(
  getSteps, getCurrentIndex,
  (steps, index) => {
    return index < steps.length - 1 ? steps[index + 1] : null;
  }
);

export function scrollIntoView() {
  const element = document.getElementById('cliento-booking');
  const boundingRect = element.getBoundingClientRect();
  if (boundingRect.top < 0) {
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
}
