import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ChevronLeft } from '../base/images';
import { Step } from '../../helpers/nav';

const Expired = ({ history }) => {
  const goBack = (ev) => {
    ev.preventDefault();
    history.push(Step.Calendar);
  };

  return (
    <>
      <div className="cb-header">
        <h2><FormattedMessage id="expired.heading" /></h2>
      </div>
      <p>
        <FormattedMessage id="expired.message" />
      </p><br />
      <div className="cb-center">
        <a href="#" onClick={goBack} className="cb-button">
          <ChevronLeft />
          <FormattedMessage id="expired.back" />
        </a>
      </div>
    </>
  );
};

export default Expired;
