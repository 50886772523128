import config from '../config';
import { errorLog } from './error-log';

function isFilterSet(filter) {
  return filter && filter.length > 0;
}

function getArray(filter) {
  return Array.isArray(filter) ? filter : [filter];
}

function indexOfAny(filters, value) {
  for (let i = 0; i < filters.length; i += 1) {
    if (value && value.toLowerCase().indexOf(filters[i].toLowerCase()) !== -1) {
      return true;
    }
  }
  return false;
}

function applyFilter(filter, item) {
  try {
    return filter(item.toJS());
  } catch (error) {
    errorLog('Apply filter failed', error);
    return true;
  }
}

export function serviceFilter(service) {
  if (typeof config.serviceFilter === 'function') {
    return applyFilter(config.serviceFilter, service);
  }
  if (!isFilterSet(config.serviceFilter)) {
    return true;
  }
  const filters = getArray(config.serviceFilter);
  return indexOfAny(filters, service.get('group'));
}

export function resourceFilter(resource) {
  if (typeof config.resourceFilter === 'function') {
    return applyFilter(config.resourceFilter, resource);
  }
  if (!isFilterSet(config.resourceFilter)) {
    return true;
  }
  const filters = getArray(config.resourceFilter);
  return indexOfAny(filters, resource.get('name'));
}

function getSortIndex(filters, value) {
  for (let i = 0; i < filters.length; i += 1) {
    if (value && value.toLowerCase().indexOf(filters[i].toLowerCase()) !== -1) {
      return i;
    }
  }
  return filters.length;
}

export function serviceGroupComparer(a, b, locale) {
  if (Array.isArray(config.serviceSortOrder)) {
    const aIdx = getSortIndex(config.serviceSortOrder, a);
    const bIdx = getSortIndex(config.serviceSortOrder, b);

    if (aIdx < bIdx) {
      return -1;
    }
    if (aIdx > bIdx) {
      return 1;
    }
  }
  return a.localeCompare(b, locale || config.locale);
}
