import { fromJS, List, Map, Set } from 'immutable';
import { createSelector } from 'reselect';
import { getLanguageCode } from 'cliento-web-utils';
import { resourceFilter, serviceFilter, serviceGroupComparer } from './filter';
import {
  getAllServicesMapping, getMergedServiceMapping, getSelectedServicesMapping,
  getSimilarResources, hasMapping, isBookable, mergeSimilarResources
} from './resource-mapping';
import { web } from './preference-keys';
import config from '../config';

function flattenSlots(resourceSlots) {
  return resourceSlots && resourceSlots.length > 0
    ? resourceSlots.map(s => s.slots).reduce((a, b) => a.concat(b))
    : [];
}

export function getFirstSlot(resourceSlots) {
  const slots = flattenSlots(resourceSlots);
  return slots.length > 0 ? slots[0] : null;
}

export function mapResourceSlots(resourceSlots, fromDate, toDate) {
  const slots = flattenSlots(resourceSlots);
  const groupedSlots = fromJS(slots).groupBy(s => s.get('date'));

  return Map().withMutations((days) => {
    const currentDate = fromDate.clone();
    while (currentDate < toDate) {
      const groupName = currentDate.format('YYYY-MM-DD');
      const slots = List().withMutations((list) => {
        if (groupedSlots.has(groupName)) {
          groupedSlots.get(groupName).forEach((slot) => {
            const parts = slot.get('time').split(':');
            list.push(slot.set('time', `${parts[0]}:${parts[1]}`));
          });
        }
      });
      days.set(currentDate.clone(), slots.sortBy(s => s.get('time')));
      currentDate.add(1, 'd');
    }
  });
}

export function filterSlots(slots, autoResourceSelection, showAllAvailableSlots) {
  if (!autoResourceSelection || showAllAvailableSlots) {
    return slots;
  }
  return slots.filter((slot, index, array) => {
    return array.map(({ time }) => time).indexOf(slot.time) === index;
  });
}

export function getResourceById(resources, resourceId) {
  return resources.find(r => r.get('id') === resourceId);
}

const getStandaloneWidgetJs = createSelector(
  state => state.settings,
  settings => settings.getIn(['prefs', web.standaloneWidgetJs])
);

const getShowUnmappedServices = createSelector(
  state => state.settings,
  settings => settings.getIn(['prefs', web.showUnmappedServices])
);

const getLocationLanguageCode = createSelector(
  state => state.settings,
  settings => getLanguageCode(settings.getIn(['prefs', 'locale']))
);

export const getFilteredResources = createSelector(
  state => getStandaloneWidgetJs(state),
  state => state.resources,
  (widgetJs, resources) => {
    return resources && resources.filter(resourceFilter);
  }
);

function shouldShowService(showUnmappedServices, vipResourceId, resources, resourceMappings, service) {
  if (config.mergeLocations || vipResourceId || showUnmappedServices) {
    return true;
  }
  const filteredResourceIds = resources && resources.map(r => r.get('id')).toArray();
  return hasMapping(resourceMappings, filteredResourceIds, service);
}

export const getFilteredServices = createSelector(
  state => getStandaloneWidgetJs(state),
  state => getShowUnmappedServices(state),
  state => state.settings.get('resourceId'),
  state => getFilteredResources(state),
  state => state.services,
  state => state.resourceMappings,
  (widgetJs, showUnmappedServices, vipResourceId, resources, services, resourceMappings) => {
    return services && services.filter((service) => {
      return shouldShowService(showUnmappedServices, vipResourceId, resources, resourceMappings, service)
        && serviceFilter(service);
    });
  }
);

export const getResourceSelectionEnabled = createSelector(
  state => state.settings,
  (settings) => {
    return !config.resourceHash && !config.mergeLocations
      && !settings.getIn(['prefs', web.autoResourceSelection])
      && !settings.getIn(['prefs', web.showResourceStep]);
  }
);

const getMergeDuplicateResources = createSelector(
  state => state.settings,
  settings => settings.getIn(['prefs', web.mergeDuplicateResources])
);

export const getSelectedResourceIds = createSelector(
  state => getFilteredResources(state),
  state => state.booking.get('resource'),
  state => getMergeDuplicateResources(state),
  (resources, resource, mergeDuplicates) => {
    if (!resource) {
      return null;
    }
    return mergeDuplicates
      ? getSimilarResources(resources, resource).map(r => r.get('id')).toArray()
      : [resource.get('id')];
  }
);

export const getCurrentResource = createSelector(
  state => getFilteredResources(state),
  state => state.booking.get('slot'),
  (resources, slot) => {
    return resources.find(r => slot && slot.resourceId === r.get('id'));
  }
);

export const getMappedResources = createSelector(
  state => getFilteredResources(state),
  state => getFilteredServices(state),
  state => state.booking.get('services'),
  state => state.resourceMappings,
  (resources, filteredServices, selectedServices, resourceMappings) => {
    return resources.map((resource) => {
      const mapping = selectedServices && !selectedServices.isEmpty()
        ? getSelectedServicesMapping(selectedServices, resourceMappings, resource)
        : getAllServicesMapping(filteredServices, resourceMappings, resource);
      return resource.merge(mapping);
    });
  }
);

export const getResourceList = createSelector(
  state => getMappedResources(state),
  state => getMergeDuplicateResources(state),
  (resources, mergeDuplicates) => {
    return mergeDuplicates
      ? mergeSimilarResources(resources)
      : resources;
  }
);

export const filterAndGroupResources = createSelector(
  state => getResourceList(state),
  (resourceList) => {
    return resourceList
      .filter(r => r.get('available'))
      .groupBy(s => s.get('group'));
  }
);

function mapServices(services, resourceIds, resourceMappings) {
  return services && services.map((service) => {
    if (!resourceIds || resourceIds.length === 0) {
      return service;
    }
    const mapping = getMergedServiceMapping(resourceMappings, resourceIds, service);
    return service
      .delete('minPrice').delete('maxPrice')
      .delete('minDuration').delete('maxDuration')
      .merge(mapping);
  });
}

export const getAllMappedServices = createSelector(
  state => getFilteredServices(state),
  state => getSelectedResourceIds(state),
  state => state.resourceMappings,
  (services, selectedResourceIds, resourceMappings) => {
    return mapServices(services, selectedResourceIds, resourceMappings);
  }
);

export const getCurrentMappedServices = createSelector(
  state => state.booking.get('services'),
  state => getCurrentResource(state),
  state => state.resourceMappings,
  (services, resource, resourceMappings) => {
    return mapServices(services, resource && [resource.get('id')], resourceMappings);
  }
);

export const getAvailableResources = createSelector(
  state => state.booking.get('services'),
  state => getFilteredResources(state),
  state => state.resourceMappings,
  (selectedServices, resources, resourceMappings) => {
    if (!selectedServices || selectedServices.isEmpty()) {
      return resources;
    }
    return resources.filter((resource) => {
      return selectedServices.every((service) => {
        return hasMapping(resourceMappings, [resource.get('id')], service);
      });
    });
  }
);

const getCurrentResourceIds = createSelector(
  state => getAvailableResources(state),
  state => getSelectedResourceIds(state),
  (availableResources, selectedResourceIds) => {
    return selectedResourceIds || availableResources.map(r => r.get('id'));
  }
);

export const getSelectedServices = createSelector(
  state => state.booking.get('services'),
  services => services?.filter(s => !s.get('addon'))
);

export const getSelectedAddonServices = createSelector(
  state => state.booking.get('services'),
  services => services?.filter(s => s.get('addon'))
);

export const getAvailableServiceIds = createSelector(
  state => getFilteredServices(state),
  state => state.resourceMappings,
  state => getCurrentResourceIds(state),
  state => state.settings.get('resourceId'),
  (services, resourceMappings, resourceIds, vipResourceId) => {
    return services.filter((service) => {
      if (vipResourceId || config.mergeLocations) {
        return true;
      }
      return hasMapping(resourceMappings, resourceIds, service);
    }).map(s => s.get('serviceId'));
  }
);

export const getBookableServiceIds = createSelector(
  state => getFilteredServices(state),
  state => state.resourceMappings,
  state => getCurrentResourceIds(state),
  state => state.settings.get('resourceId'),
  (services, resourceMappings, resourceIds, vipResourceId) => {
    return services.filter((service) => {
      return isBookable(resourceMappings, resourceIds, service, vipResourceId);
    }).map(s => s.get('serviceId'));
  }
);

export const getAvailableAddonServiceIds = createSelector(
  state => getSelectedServices(state),
  state => getBookableServiceIds(state),
  state => state.addonMappings,
  (selectedServices, bookableServiceIds, addonMappings) => {
    const addonSrvIds = selectedServices?.keySeq()
      .map(key => addonMappings.find(m => m.get('srvId') === key))
      .map(mapping => mapping?.get('addonSrvIds') || List());

    return Set(addonSrvIds?.flatten())
      .filter(id => bookableServiceIds.includes(id));
  }
);

const filterServices = createSelector(
  state => getAllMappedServices(state),
  state => getSelectedResourceIds(state),
  state => state.settings.get('resourceId'),
  state => state.resourceMappings,
  (mappedServices, selectedResourceIds, vipResourceId, resourceMappings) => {
    return mappedServices
      .filter((service) => {
        return vipResourceId || !selectedResourceIds ||
          hasMapping(resourceMappings, selectedResourceIds, service);
      });
  }
);

const sortGroupedServices = (groupedServices, locale) => {
  return config.mergeLocations
    ? groupedServices.sortBy((value, key) => key, (a, b) => serviceGroupComparer(a, b, locale))
    : groupedServices;
};

export const getGroupedServices = createSelector(
  state => filterServices(state),
  state => getLocationLanguageCode(state),
  (services, locale) => {
    const groupedServices = services.filter(s => !s.get('addon'))
      .groupBy(s => s.get('group'));

    return sortGroupedServices(groupedServices, locale);
  }
);

export const getGroupedAddonServices = createSelector(
  state => filterServices(state),
  state => getAvailableAddonServiceIds(state),
  (services, availableAddonServiceIds) => {
    const groupedServices = services
      .filter(s => s.get('addon') && availableAddonServiceIds.includes(s.get('serviceId')))
      .sortBy(s => s.get('name'))
      .groupBy(s => s.get('group'));

    return groupedServices;
  }
);

export const getSingleAvailableService = createSelector(
  state => filterServices(state),
  (services) => {
    return services.size === 1
      ? services.first()
      : null;
  }
);
