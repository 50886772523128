/* eslint-disable key-spacing, quote-props */

const strings = {
  'select':                   'Select',
  'continue':                 'Continue',
  'back':                     'Back',
  'fromPrice':                'From {price}',
  'free':                     'Free',
  'durationHours':            '{hours} h',
  'durationMinutes':          '{minutes} min',
  'optional':                 'optional',
  'yes':                      'Yes',
  'no':                       'No',

  'location.heading':         'Where do you want to book?',

  'service.heading':          'What do you want to book?',
  'service.multiple':         'Book multiple services',
  'service.showMore':         'More',
  'service.notBookable':      'Contact us for booking',
  'service.noServices':       'There are no services to book',

  'resource.heading':         'Who do you want to book?',
  'resource.select':          'Select {label}',
  'resource.showAll':         'Show all',
  'resource.noResources':     'There is nothing to book',

  'calendar.heading':         'Select a time',
  'calendar.progress':        'Checking availability',
  'calendar.noSlots':         'Not available',
  'calendar.week':            'week {week}',
  'calendar.today':           'Today',
  'calendar.previous':        'Previous week',
  'calendar.next':            'Next week',
  'calendar.noneThisWeek':    'Did not find any available times this week',
  'calendar.nextAvailable':   'Next available time is {nextAvailable}',
  'calendar.showAvailable':   'Show available times',
  'calendar.noneAtAll':       'Did not find any available times within {duration}',
  'calendar.notAvailable':    'The service is not available',

  'customer.heading':         'Enter your information',
  'customer.name':            'Name',
  'customer.firstName':       'First name',
  'customer.lastName':        'Last name',
  'customer.email':           'E-mail',
  'customer.confirmEmail':    'E-mail (again)',
  'customer.phone':           'Phone',
  'customer.confirmPhone':    'Phone (again)',
  'customer.pno':             'Personal number',
  'customer.disabledPno':     'Personal number',
  'customer.foreignPno':      'Other ID number',
  'customer.orgNo':           'Org.no',
  'customer.orgName':         'Company',
  'customer.vehicleRegNo':    'Vehicle reg.no',
  'customer.note':            'Message',
  'customer.changeCard':      'Change card',
  'customer.acceptTerms':     'I accept the terms',
  'customer.allowForeignPno': 'I don\'t have a swedish personal number',
  'customer.foreignPnoHelp':  'Enter your coordination number or foreign personal number',
  'customer.orgOptional':     'Company details is optional if you are booking as a private person',

  'verify.heading':           'Confirm booking',
  'verify.confirmPinHeading': 'We have sent a PIN code to {phone}',
  'verify.confirmPinMessage': 'Confirm the booking by entering the PIN code',
  'verify.confirmMessage':    'Does everything look good?',
  'verify.confirmBooking':    'Confirm booking',
  'verify.confirmAndPay':     'Book and pay {amount}',
  'verify.progress':          'Confirming your booking...',
  'verify.beforeUnload':      'Your booking is not completed, are you sure you want to leave the page?',

  'payment.heading':          'Pay booking',
  'payment.providerHeading':  'Select payment option',
  'payment.providerSwish':    'Pay with Swish',
  'payment.klarnaLater':      'Invoice with Klarna',
  'payment.klarnaOverTime':   'Partial payment with Klarna',
  'payment.providerCard':     'Pay with card',
  'payment.providerNone':     'Free booking',
  'payment.phoneNumber':      'Enter phone number',
  'payment.cardDetails':      'Enter card details',
  'payment.swishHeader':      'Open Swish app',
  'payment.swishInfo':        'Open the Swish app and follow the instructions to confirm your booking.',
  'payment.swishButton':      'Open Swish app',
  'payment.providerNoneInfo': 'The booking is free of charge, confirm to complete your booking.',

  'timer.remaining':          'Remaining time: {time}',

  'expired.heading':          'The time is up!',
  'expired.message':          'The time to verify your booking has expired! ' +
                              'Please go back to make a new booking.',
  'expired.back':             'Go back',

  'confirmation.heading':     'Thank you for your booking!',
  'confirmation.email':       'A confirmation has been sent to {email}.',
  'confirmation.reminder':    'A reminder will be sent to {phone} {interval} hours before the booking.',
  'confirmation.postInfo':    'Press the button to finalize your booking.',
  'confirmation.postButton':  'Finalize booking',

  'summary.toggle':           'Show booking',
  'summary.location':         'Location',
  'summary.date':             'Date',
  'summary.time':             'Time',
  'summary.resource':         'Resource',
  'summary.service':          'Service',
  'summary.services':         'Services',
  'summary.address':          'Address',
  'summary.customer':         'Your details',
  'summary.vehicle':          'Vehicle',
  'summary.company':          'Company',
  'summary.priceExVat':       'Price ex. VAT',
  'summary.price':            'Price',
  'summary.discount':         'Discount',
  'summary.vat':              'VAT {vatPct}%',
  'summary.amountToPay':      'Amount to pay',
  'summary.amountPaid':       'Paid',
  'summary.card':             'Card',

  'validation.required':      'required field',
  'validation.phone':         'invalid phone number',
  'validation.phoneHint':     'The number must start with 0 or country code (e.g. 0046 or +46)',
  'validation.phoneMismatch': 'matchar inte',
  'validation.email':         'invalid e-mail address',
  'validation.emailMismatch': 'matchar inte',
  'validation.pno':           'invalid personal number',
  'validation.orgNo':         'invalid org.number',
  'validation.orgNoRequired': 'required field if company is filled',
  'validation.minimumAge':    'age must be minimum {minimumAge} years',
  'validation.birthDate':     'birth date must be {birthDate} or later',

  'placeholder.name':         'First and last name',
  'placeholder.firstName':    'First name',
  'placeholder.lastName':     'Last name',
  'placeholder.email':        'E-mail',
  'placeholder.confirmEmail': 'E-mail (again)',
  'placeholder.phone':        'Phone',
  'placeholder.confirmPhone': 'Phone (again)',
  'placeholder.pno':          'Personal number',
  'placeholder.disabledPno':  'Personal number',
  'placeholder.foreignPno':   'Other ID number',
  'placeholder.orgNo':        'Org.no',
  'placeholder.orgName':      'Company',
  'placeholder.vehicleRegNo': 'Vehicle reg.no',
  'placeholder.note':         'Message',

  'error.unavailable':        'The account could not be found. Check the link and try again.',
  'error.maintenance':        'We are updating the system with new functionality. Please come back later.',
  'error.unknown':            'An unexpected error occurred. Go back and try again.',
  'error.notAllowed':         'Booking is not allowed. Contact the booking location.',
  'error.slotTaken':          'The time is no longer available. Go back and select another time.',
  'error.wrongPin':           'Wrong PIN code. Check the code and try again.',
  'error.payment':            'Payment failed. Check your details and try again.',
  'error.paymentDeclined':    'Payment declined. Go back and try again.',
  'error.paymentHeading':     'Payment failed',
  'error.paymentNA':          'Payment is not available for the selected resource.',
  'error.paymentRequiredNA':  'The service requires payment, but no payment providers are available.',
  'error.paymentProviderNA':  'Payment provider is not available. Try another payment provider.',
  'error.swishNotEnrolled':   'The phone number is not connected to Swish. Check your details and try again.',
  'error.swishAlreadyExists': 'There is already an ongoing Swish payment. Cancel the payment in the Swish app and try again.',
  'error.back':               'Go back',

  'error.refNotValid':        'Booking reference is missing or not valid. Please check the link and try again.',
  'error.refAlreadyUsed':     'Your booking reference has already been used for a booking.',
  'error.alreadyBookedPno':   'There is already a booking for this personal number.',
  'error.alreadyBookedMob':   'There is already a booking for this phone number.',

  'footer.exVatInfo':         'All prices are excl. VAT',
  'footer.privacy':           'Privacy policy',
  'footer.cliento':           'Booking by Cliento'
};

export default strings;
