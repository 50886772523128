import config from '../config';
import { getDirection, getStepFromLocation } from './nav';
import { trackBookingCompleted, trackPageView } from './tracking';
import { getPreference } from './settings';
import { debugLog } from './debug-log';
import { errorLog } from './error-log';
import { web } from './preference-keys';
import { getResourceById } from './booking';

export function triggerNavigateEvent(prevLocation, location, firstStep) {
  const from = getStepFromLocation(prevLocation) || firstStep;
  const to = getStepFromLocation(location);
  const direction = getDirection(from, to);
  const navigation = { from, to, direction };

  debugLog('Navigate', navigation);

  if (typeof config.onNavigate === 'function') {
    try {
      config.onNavigate(navigation);
    } catch (error) {
      errorLog('onNavigate event failed', error);
    }
  }

  trackPageView();
}

function getBookingEventData(state) {
  const { booking, resources, settings } = state;
  const slot = booking.get('slot');
  const customer = booking.get('customer');
  const services = booking.get('services');
  const bookingRef = booking.get('bookingRef');
  const resource = getResourceById(resources, slot.resourceId);

  return {
    bookingRef,
    customer,
    date: slot.date,
    time: slot.time,
    length: slot.length,
    price: slot.price,
    resource: resource && resource.get('name'),
    services: services && services.map(s => s.get('name')).toArray(),
    location: getPreference(settings, web.companyName),
    locationId: settings.get('stableId')
  };
}

export function triggerReservedEvent(state) {
  const booking = getBookingEventData(state);

  debugLog('Reserved', booking);

  if (typeof config.onReserved === 'function') {
    try {
      config.onReserved(booking);
    } catch (error) {
      errorLog('onReserved event failed', error);
    }
  }
}

export function triggerCompletedEvent(state) {
  const booking = getBookingEventData(state);

  debugLog('Completed', booking);

  if (typeof config.onCompleted === 'function') {
    try {
      config.onCompleted(booking);
    } catch (error) {
      errorLog('onCompleted event failed', error);
    }
  }

  trackBookingCompleted(booking);
}
