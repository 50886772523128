import React from 'react';
import { isSelect } from '../../helpers/forms';
import { required } from '../../helpers/validation';
import CustomerField from '../base/customer-field';

const Children = ({ type, options, required, placeholder }) => {
  if (isSelect(type) && Array.isArray(options)) {
    return (
      <>
        <option value="" disabled={required}>{placeholder}</option>
        {options.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </>
    );
  }
  return null;
};

const CustomFields = ({ customFields }) => {
  if (!customFields || customFields.isEmpty()) {
    return null;
  }
  return (
    <section>
      {customFields.map((field) => {
        const { key, type, label, helpText, placeholder, options, required: isRequired } = field.toJS();
        return (
          <CustomerField
            key={key}
            type={type}
            name={`custom.${key}`}
            label={label}
            helpText={helpText}
            placeholder={placeholder}
            optional={!isRequired}
            validate={isRequired ? required : null}
          >
            <Children type={type} options={options} required={isRequired} placeholder={placeholder} />
          </CustomerField>
        );
      })}
    </section>
  );
};

export default CustomFields;
