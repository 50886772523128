import { injectIntl } from 'react-intl';
import { isSet } from '../../helpers/strings';

export function formattedDuration(intl, duration) {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  const parts = [];

  if (hours > 0) {
    parts.push(intl.formatMessage({ id: 'durationHours' }, { hours }));
  }
  if (minutes > 0) {
    parts.push(intl.formatMessage({ id: 'durationMinutes' }, { minutes }));
  }
  return parts.join(' ');
}

export function formattedDurationRange(intl, duration, minDuration, maxDuration) {
  if (!isSet(duration) && !isSet(minDuration)) {
    return null;
  }
  return minDuration !== maxDuration
    ? `${formattedDuration(intl, minDuration)} - ${formattedDuration(intl, maxDuration)}`
    : formattedDuration(intl, isSet(duration) ? duration : minDuration);
}

const Duration = ({ intl, duration, minDuration, maxDuration }) => {
  return formattedDurationRange(intl, duration, minDuration, maxDuration);
};

export default injectIntl(Duration);
