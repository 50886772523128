import config from '../config';

function mappingKey(resourceId, service) {
  return `${resourceId}:${service.get('serviceId')}`;
}

export function getServiceMapping(resourceMappings, resourceId, service) {
  const mapping = resourceMappings.get(mappingKey(resourceId, service));
  return mapping && mapping.get('serviceId') ? mapping : null;
}

export function hasMapping(resourceMappings, resourceIds, service) {
  return resourceIds.some((resourceId) => {
    return getServiceMapping(resourceMappings, resourceId, service) !== null;
  });
}

export function isBookable(resourceMappings, resourceIds, service, vipResourceId) {
  if (vipResourceId) {
    return service.get('webAllowBooking');
  }
  if (config.mergeLocations && resourceMappings.isEmpty()) {
    return true;
  }
  return resourceIds.some((resourceId) => {
    const mapping = getServiceMapping(resourceMappings, resourceId, service);
    return mapping && mapping.get('webAllowBooking');
  });
}

export function getSelectedServicesMapping(services, resourceMappings, resource) {
  let price = null;
  let duration = null;
  let available = true;
  let bookable = true;
  let priceFrom = false;

  services.forEach((service) => {
    const mapping = getServiceMapping(resourceMappings, resource.get('id'), service);

    if (!mapping) {
      available = false;
    }
    if (mapping && !mapping.get('webAllowBooking')) {
      bookable = false;
    }
    if (mapping && mapping.get('webShowDuration')) {
      duration += parseInt(mapping.get('serviceDuration') || 0, 10);
    }
    if (mapping && mapping.get('webShowPrice')) {
      if (mapping.get('priceFrom')) {
        priceFrom = true;
      }
      price += parseInt(mapping.get('price') || 0, 10);
    }
  });

  return {
    price,
    duration,
    available,
    bookable,
    priceFrom
  };
}

function getInitialMappingValues(additionalValues) {
  return {
    minPrice: null,
    maxPrice: null,
    priceFrom: false,
    minDuration: null,
    maxDuration: null,
    webShowPrice: false,
    webShowDuration: false,
    ...additionalValues
  };
}

function mergeMinValue(values, param, value) {
  if (values[param] === null || value < values[param]) {
    values[param] = value;
  }
}

function mergeMaxValue(values, param, value) {
  if (values[param] === null || value > values[param]) {
    values[param] = value;
  }
}

function mergePriceAndDurationForMapping(values, mapping) {
  if (mapping && mapping.get('webShowPrice')) {
    mergeMinValue(values, 'minPrice', mapping.get('price'));
    mergeMaxValue(values, 'maxPrice', mapping.get('price'));

    if (mapping.get('priceFrom')) {
      values.priceFrom = true;
    }
    values.webShowPrice = true;
  }
  if (mapping && mapping.get('webShowDuration') && mapping.get('serviceDuration') > 0) {
    mergeMinValue(values, 'minDuration', mapping.get('serviceDuration'));
    mergeMaxValue(values, 'maxDuration', mapping.get('serviceDuration'));

    values.webShowDuration = true;
  }
}

function mergePriceAndDurationForResource(values, resource) {
  const minPrice = resource.get('minPrice') || resource.get('price');
  const maxPrice = resource.get('maxPrice') || resource.get('price');
  if (minPrice || minPrice === 0) {
    mergeMinValue(values, 'minPrice', minPrice);
    mergeMaxValue(values, 'maxPrice', maxPrice);

    if (resource.get('priceFrom')) {
      values.priceFrom = true;
    }
    values.webShowPrice = true;
  }

  const minDuration = resource.get('minDuration') || resource.get('duration');
  const maxDuration = resource.get('maxDuration') || resource.get('duration');
  if (minDuration > 0) {
    mergeMinValue(values, 'minDuration', minDuration);
    mergeMaxValue(values, 'maxDuration', maxDuration);

    values.webShowDuration = true;
  }
}

export function getAllServicesMapping(services, resourceMappings, resource) {
  const values = getInitialMappingValues({
    available: false,
    bookable: false
  });

  services.forEach((service) => {
    const mapping = getServiceMapping(resourceMappings, resource.get('id'), service);

    if (mapping) {
      values.available = true;
    }
    if (mapping && mapping.get('webAllowBooking')) {
      values.bookable = true;
    }

    mergePriceAndDurationForMapping(values, mapping);
  });

  return values;
}

export function getMergedServiceMapping(resourceMappings, resourceIds, service) {
  const values = getInitialMappingValues({
    prefs: service?.get('prefs') || {}
  });

  resourceIds.forEach((resourceId) => {
    const mapping = getServiceMapping(resourceMappings, resourceId, service);

    mergePriceAndDurationForMapping(values, mapping);

    if (mapping && mapping.get('prefs')) {
      values.prefs = { ...values.prefs, ...mapping.get('prefs').toJS() };
    }
  });

  return values;
}

function getMergedResourceMapping(mappedResources) {
  const values = getInitialMappingValues({
    available: false
  });

  mappedResources.forEach((resource) => {
    if (resource.get('available')) {
      values.available = true;
    }

    mergePriceAndDurationForResource(values, resource);
  });

  return values;
}

export function mergeSimilarResources(resources) {
  return resources.groupBy(r => r.get('name'))
    .map((similarResources) => {
      const mapping = getMergedResourceMapping(similarResources);
      return similarResources.first()
        .delete('price').delete('duration')
        .merge(mapping);
    }).toList();
}

export function getSimilarResources(resources, resource) {
  return resources.filter(r => r.get('name') === resource.get('name'));
}
