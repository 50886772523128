/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeKlarnaMethod } from '../../actions/payment';
import { getWidgetData } from '../../helpers/klarna';
import { debugLog } from '../../helpers/debug-log';

class KlarnaForm extends Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
  }

  componentDidMount() {
    const { category, session } = this.props;
    this.instanceId = `klarna-payment-${category}`;
    this.mounted = true;

    if (session) {
      this.loadWidget();
    }
  }

  componentDidUpdate(prevProps) {
    const { amount, selected } = this.props;
    const fullPriceUpdated = prevProps.amount !== amount;
    const selectedUpdated = !prevProps.selected && selected;

    if (selectedUpdated || fullPriceUpdated) {
      this.loadWidget();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadWidget = () => {
    const { category, enabled } = this.props;
    if (!enabled || !this.container.current) {
      return;
    }
    try {
      const widgetData = getWidgetData(this.props);
      debugLog('Klarna: Loading widget', widgetData);
      window.Klarna.Payments.load(
        {
          container: this.container.current,
          instance_id: this.instanceId,
          payment_method_categories: [category]
        },
        widgetData,
        this.widgetLoaded
      );
    } catch (error) {
      this.error('Error loading Klarna widget', error);
      throw error;
    }
  };

  widgetLoaded = ({ show_form, error }) => {
    const { removeKlarnaMethod, category } = this.props;
    if (!this.mounted) {
      // Klarna widget will call this method 20 seconds after a successful payment
      // with { show_form: false } to make sure the widget is not displayed
      return;
    }
    if (!show_form) {
      if (error) {
        this.error('Error loading Klarna widget', error);
      }
      removeKlarnaMethod(category);
    }
  };

  onSubmit = () => {
    try {
      window.Klarna.Payments.authorize({
        instance_id: this.instanceId
      }, {}, this.paymentAuthorized);
    } catch (error) {
      this.error('Error authorizing Klarna payment', error);
      throw error;
    }
  };

  paymentAuthorized = ({ approved, authorization_token, show_form, error }) => {
    const { onConfirm, removeKlarnaMethod, category } = this.props;
    if (approved) {
      onConfirm({
        paymentProvider: 'Klarna',
        klarnaAuthToken: authorization_token,
        category
      });
    } else if (!show_form) {
      if (error) {
        this.error('Error authorizing Klarna payment', error);
      }
      removeKlarnaMethod(category);
    }
  };

  error = (message, error) => {
    // eslint-disable-next-line no-console
    console.error(message, error);
  };

  render() {
    const { selected, enabled } = this.props;

    return (
      <>
        {selected && !enabled && (
          <div className="cb-input-group">
            <strong>Betalsättet är inte tillgängligt för det här köpet.</strong>
          </div>
        )}
        {selected && <div className="cb-input-group" ref={this.container} />}
      </>
    );
  }
}

KlarnaForm.propTypes = {
  amount: PropTypes.number,
  category: PropTypes.any.isRequired,
  enabled: PropTypes.bool,
  selected: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const { payment, booking } = state;

  return {
    session: payment.get('klarnaSession'),
    paymentAmountIncVat: booking.get('paymentAmountIncVat'),
    saleItems: booking.get('saleItems')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeKlarnaMethod: (method) => dispatch(removeKlarnaMethod(method))
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(KlarnaForm);
