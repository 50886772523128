import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import { dayPickerClassNames, dayPickerInputClassNames } from '../../styles/day-picker-class-names';
import config from '../../config';

const DateInput = ({ input: { value, onChange }, placeholder }) => {
  return (
    <DayPickerInput
      formatDate={formatDate}
      parseDate={parseDate}
      value={value}
      format="L"
      placeholder={placeholder || ''}
      classNames={dayPickerInputClassNames}
      inputProps={{ className: 'cb-input date' }}
      dayPickerProps={{
        locale: config.locale,
        localeUtils: MomentLocaleUtils,
        classNames: dayPickerClassNames
      }}
      onDayChange={day => onChange(day ? moment(day).format('L') : null)}
    />
  );
};

export default DateInput;
