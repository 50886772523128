import React from 'react';
import CheckBox from './checkbox';

const CheckboxField = ({ input: { checked, onChange }, label }) => {
  return (
    <div>
      <div className="cb-checkbox-container" onClick={() => onChange(!checked)}>
        <CheckBox checked={checked} />
        <label>{label}</label>
      </div>
    </div>
  );
};

export default CheckboxField;
