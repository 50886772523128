import { getJson, prefixUrl, prefixVipUrl } from '../helpers/network';
import { initPhoneNumber } from '../helpers/phone-number';
import { getPreference } from '../helpers/settings';
import { initStandAlone } from '../helpers/style';
import { web } from '../helpers/preference-keys';
import config, { initStandaloneWidgetJs } from '../config';

export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';

export function initSettings(settings) {
  config.stableId = settings.get('stableId');
  config.currency = settings.get('currencyCode') || 'SEK';

  const countryCode = settings.get('preferredE164CountryCode');
  initPhoneNumber(String(countryCode) || '46', '0', '00');

  const standaloneWidgetJs = getPreference(settings, web.standaloneWidgetJs);
  initStandaloneWidgetJs(standaloneWidgetJs);

  const companyName = getPreference(settings, web.companyName);
  const resourceName = settings.get('resourceName');
  initStandAlone(companyName, resourceName);
}

function fetchSettingsSuccess(settings) {
  initStandAlone();
  return {
    type: FETCH_SETTINGS_SUCCESS,
    settings
  };
}

export function fetchSettings(id, order = 0) {
  const url = config.resourceHash ?
    prefixVipUrl(`/settings/${config.resourceHash}/`) :
    prefixUrl('/settings/', id);

  return (dispatch) => {
    return getJson(url)
      .then(({ data }) => {
        dispatch(fetchSettingsSuccess(data.settings ? {
          ...data.settings,
          resourceId: data.resourceId,
          resourceName: data.resourceName
        } : {
          ...data,
          order
        }));
      });
  };
}
