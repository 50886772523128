import { fromJS, Map } from 'immutable';

import { FETCH_CUSTOM_FIELDS_SUCCESS } from '../actions/custom-fields';
import { SET_BOOKING_SLOT, RESET_BOOKING } from '../actions/booking';

export default function customFields(state = Map(), action = null) {
  switch (action.type) {
    case FETCH_CUSTOM_FIELDS_SUCCESS:
      return state.clear()
        .set('resourceHash', action.resourceHash)
        .set('serviceIds', action.serviceIds)
        .set('fields', fromJS(action.fields));

    case RESET_BOOKING:
    case SET_BOOKING_SLOT:
      return state.clear();

    default:
      return state;
  }
}
