import React from 'react';
import { FormattedMessage } from 'react-intl';

const Logo = () => (
  <div className="cb-logo">
    <a href="https://cliento.com/" target="_blank">
      <FormattedMessage id="footer.cliento" />
    </a>
  </div>
);

export default Logo;
