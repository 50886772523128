import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupedAddonServices, getSelectedAddonServices } from '@Helpers/booking';
import { addBookingService, removeBookingService } from '../../actions/booking';
import Modal from '../base/modal';
import ServiceGroup from './service-group';
import { ChevronRight } from '../base/images';

const AddonService = ({
  availableServiceIds, bookableServiceIds, alwaysShowDescription,
  onContinue, onCancel
}) => {
  const dispatch = useDispatch();
  const groupedAddonServices = useSelector(getGroupedAddonServices);
  const selectedAddonServices = useSelector(getSelectedAddonServices);

  const serviceSelected = useCallback((service) => {
    const serviceId = service.get('serviceId');
    const selected = selectedAddonServices?.has(serviceId);

    if (selected) {
      dispatch(removeBookingService(service));
    } else {
      dispatch(addBookingService(service));
    }
  }, [selectedAddonServices]);

  return (
    <Modal title="Rekommenderade tillval" onClose={onCancel}>
      {groupedAddonServices.entrySeq().map(([group, services]) => {
        return (
          <ServiceGroup
            key={group}
            name={group}
            services={services}
            multipleServices
            selectedServices={selectedAddonServices}
            availableServiceIds={availableServiceIds}
            bookableServiceIds={bookableServiceIds}
            alwaysShowDescription={alwaysShowDescription}
            showHeader={groupedAddonServices.size > 1}
            serviceSelected={serviceSelected}
          />
        );
      })}
      {groupedAddonServices.isEmpty() && (
        <div className="cb-service-empty">
          <FormattedMessage id="service.noServices" />
        </div>
      )}
      <div className="cb-service-button-container">
        <button type="button" className="cb-button" onClick={onContinue}>
          <FormattedMessage id="continue" />
          <ChevronRight />
        </button>
      </div>
    </Modal>
  );
};

export default AddonService;
