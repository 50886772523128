import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { isSet } from '../../helpers/strings';
import Price from '../intl/price';
import Duration from '../intl/duration';
import CheckBox from '../base/checkbox';
import { ChevronDown, ChevronRight } from '../base/images';

class ServiceItem extends React.Component {
  state = {
    showDescription: this.props.alwaysShowDescription
  };

  toggleDescription = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState(({ showDescription }) => {
      return {
        showDescription: !showDescription
      };
    });
  };

  render() {
    const { showDescription } = this.state;
    const {
      service, selected, available, bookable, multipleServices,
      alwaysShowDescription, onSelect
    } = this.props;
    const {
      name, description, minDuration, maxDuration, serviceDuration,
      minPrice, maxPrice, price, priceFrom, webShowPrice, webShowDuration
    } = service.toObject();
    const hasDescription = description && description.length > 0;

    const className = classNames({
      'cb-service-item': true,
      selected: multipleServices && selected,
      available,
      bookable
    });

    return (
      <div className={className} onClick={bookable ? onSelect : null}>
        {available && !bookable && <span className="cb-not-bookable"><FormattedMessage id="service.notBookable" /></span>}
        {available && bookable && multipleServices && <CheckBox checked={selected} />}
        {available && bookable && !multipleServices && (
          <button type="button" className="cb-nav-item active">
            <ChevronRight />
          </button>
        )}
        <strong>{name}</strong>
        {webShowDuration && isSet(serviceDuration) && <span><Duration duration={serviceDuration} /></span>}
        {isSet(minDuration) && <span><Duration minDuration={minDuration} maxDuration={maxDuration} /></span>}
        {webShowPrice && isSet(price) && <span><Price price={price} priceFrom={priceFrom} /></span>}
        {isSet(minPrice) && <span><Price minPrice={minPrice} maxPrice={maxPrice} priceFrom={priceFrom} /></span>}
        {hasDescription && !alwaysShowDescription && (
          <a href="#" onClick={this.toggleDescription}>
            <FormattedMessage id="service.showMore" />
            {showDescription ? <ChevronDown /> : <ChevronRight />}
          </a>
        )}
        {hasDescription && showDescription && <p>{description}</p>}
      </div>
    );
  }
}

export default ServiceItem;
