import React from 'react';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ChevronLeft, ChevronRight } from '../base/images';

const CalendarNav = ({ week, minDate, maxDate, navPrev, navNext, intl }) => {
  const startDate = moment(week).startOf('isoWeek');
  const endDate = moment(week).endOf('isoWeek');
  const previousDisabled = startDate.isSameOrBefore(minDate, 'day');
  const nextDisabled = maxDate && endDate.isSameOrAfter(maxDate, 'day');
  const previousClass = previousDisabled ? 'cb-nav-item disabled' : 'cb-nav-item active';
  const nextClass = nextDisabled ? 'cb-nav-item disabled' : 'cb-nav-item active';

  return (
    <div className="cb-calendar-nav">
      <span className="cb-calendar-prev">
        <a href="#" className={previousClass} onClick={navPrev} title={intl.formatMessage({ id: 'calendar.previous' })}>
          <ChevronLeft />
        </a>
      </span>
      <span className="cb-calendar-next">
        <a href="#" className={nextClass} onClick={navNext} title={intl.formatMessage({ id: 'calendar.next' })}>
          <ChevronRight />
        </a>
      </span>
      <span className="cb-calendar-label">
        <strong>{startDate.format('D MMM').replace('.', '')} - {endDate.format('D MMM').replace('.', '')}</strong>{', '}
        <small><FormattedMessage id="calendar.week" values={{ week: startDate.format('W') }} /></small>
      </span>
    </div>
  );
};

export default injectIntl(CalendarNav);
