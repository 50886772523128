import { Map } from 'immutable';
import config from '../config';

import { SET_BOOKING_LOCATION } from '../actions/booking';

export default function settings(state = Map(), action = null) {
  switch (action.type) {
    case SET_BOOKING_LOCATION:
      return state.clear().merge(action.location)
        .mergeDeep({
          prefs: { ...config.settings },
          customTexts: { ...config.customTexts }
        });

    default:
      return state;
  }
}
