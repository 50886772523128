import { getJson, prefixUrl } from '../helpers/network';
import { setLoading, resetLoading } from './app';

export const FETCH_CUSTOM_FIELDS_SUCCESS = 'FETCH_CUSTOM_FIELDS_SUCCESS';

function fetchCustomFieldsSuccess(resourceHash, serviceIds, fields) {
  return {
    type: FETCH_CUSTOM_FIELDS_SUCCESS,
    resourceHash,
    serviceIds,
    fields
  };
}

function getCustomFieldsParams({ booking, resources }) {
  const slot = booking.get('slot');
  const services = booking.get('services');
  const slotResource = r => slot && slot.resourceId === r.get('id');
  const resource = booking.get('resource') || resources.find(slotResource);

  return {
    resourceHash: resource && resource.get('hashId'),
    serviceIds: services && services.map(s => s.get('serviceId')).join(',')
  };
}

export function fetchCustomFields() {
  return (dispatch, getState) => {
    const { resourceHash, serviceIds } = getCustomFieldsParams(getState());

    if (!resourceHash || !serviceIds) {
      return Promise.resolve();
    }

    dispatch(setLoading());
    return getJson(prefixUrl(`/custom-fields/resource/${resourceHash}?srvIds=${serviceIds}`))
      .then(({ data }) => dispatch(fetchCustomFieldsSuccess(resourceHash, serviceIds, data)))
      .catch((error) => {
        dispatch(resetLoading());
        return Promise.reject(error);
      });
  };
}
