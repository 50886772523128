import { combineReducers } from 'redux';

import booking from './booking';
import customFields from './custom-fields';
import locations from './locations';
import settings from './settings';
import services from './services';
import addonMappings from './addon-mappings';
import resources from './resources';
import resourceMappings from './resource-mappings';
import slots from './slots';
import payment from './payment';

const rootReducer = combineReducers({
  booking,
  customFields,
  locations,
  settings,
  services,
  addonMappings,
  resources,
  resourceMappings,
  slots,
  payment
});

export default rootReducer;
