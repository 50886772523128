import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setBookingExpired } from '../../actions/booking';

class Timer extends React.Component {
  state = {
    timeLeft: null
  };

  componentDidMount() {
    this.initTimer(this.props.expiration);
  }

  componentWillUnmount() {
    this.resetTimer();
  }

  initTimer = (expiration) => {
    this.resetTimer();
    this.countdown(expiration);
    this.interval = setInterval(() => {
      this.countdown(expiration);
    }, 1000);
  };

  countdown = (expiration) => {
    const now = moment();
    const timeLeft = moment(expiration - now);

    if (timeLeft > 0) {
      this.setState({ timeLeft });
    } else {
      this.props.setBookingExpired();
      this.resetTimer();
    }
  };

  resetTimer = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  render() {
    const { timeLeft } = this.state;
    const { show } = this.props;

    return timeLeft && show && (
      <small>
        <FormattedMessage
          id="timer.remaining"
          values={{ time: timeLeft.format('mm:ss') }}
        />
      </small>
    );
  }
}

const mapStateToProps = (state) => {
  const { booking } = state;

  return {
    expiration: booking.get('expiration')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookingExpired: () => dispatch(setBookingExpired())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
