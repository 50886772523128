export const InputType = {
  Text: 'text',
  Date: 'date',
  Select: 'select',
  Checkbox: 'checkbox'
};

export const isTextInput = type => type === InputType.Text;
export const isDateInput = type => type === InputType.Date;
export const isSelect = type => type === InputType.Select;
export const isCheckbox = type => type === InputType.Checkbox;

export const toggleCheckboxWithLink = (ev, method, checked) => {
  const { tagName } = ev.target;

  if (tagName && tagName.toLowerCase() === 'a') {
    return;
  }
  method(!checked);
};
