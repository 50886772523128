import { fromJS, List } from 'immutable';

import { FETCH_REF_DATA_SUCCESS } from '../actions/ref-data';

export default function resources(state = List(), action = null) {
  switch (action.type) {
    case FETCH_REF_DATA_SUCCESS:
      return action.resources
        ? fromJS(action.resources)
        : state;

    default:
      return state;
  }
}
