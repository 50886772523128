import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ChevronLeft } from '../base/images';
import Error from '../base/error';

const PaymentError = ({ error, onClose }) => {
  const handleClose = (ev) => {
    ev.preventDefault();
    onClose();
  };

  return (
    <>
      <div className="cb-header">
        <h2><FormattedMessage id="error.paymentHeading" /></h2>
      </div>
      <div className="cb-error">
        <Error error={error} />
      </div><br />
      <div className="cb-center">
        <a href="#" onClick={handleClose} className="cb-button">
          <ChevronLeft />
          <FormattedMessage id="error.back" />
        </a>
      </div>
    </>
  );
};

export default PaymentError;
