import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getCurrentMappedServices, getCurrentResource } from '../helpers/booking';
import { formatPhoneNumber } from '../helpers/phone-number';
import { cardDescription } from '../helpers/strings';
import { getPaymentAmountExVat, getTotalAmount, getVatPctAmounts } from '../helpers/payment';
import { getBookingAddress, getMergedWebSettings, getPreference } from '../helpers/settings';
import { web } from '../helpers/preference-keys';
import { enableLocationSelection } from '../config';
import CustomFieldValue from './intl/custom-field-value';
import Duration from './intl/duration';
import Price from './intl/price';

const Summary = ({
  resourceName, slot, mappedServices, customer, paymentMethod, paymentStatus,
  pricesExVat, paymentAmountIncVat, paymentAmountExVat, customFields, vatPctAmounts,
  showCustomer, confirmation, resourceLabel, customMessageLabel, companyName, address,
  useForeignPno, webSettings, paymentRequired, totalAmount, discountVoucher
}) => {
  const { vehicleRegNo, orgName, orgNo, name, phone, email, pno, foreignPno, note } = customer || {};
  const hasCompany = orgName || orgNo;
  const date = moment(slot && `${slot.date} ${slot.time}`);
  const { autoSelectFirstAvailableSlot } = webSettings;
  const showDateTime = !autoSelectFirstAvailableSlot;

  return (
    <div className={confirmation ? 'cb-summary cb-confirmation' : 'cb-summary'}>
      <div className="cb-summary-table">
        <table>
          <tbody>
            {showDateTime && (
              <>
                <tr>
                  <th><FormattedMessage id="summary.date" /></th>
                  <td>{date.format('dddd LL')}</td>
                </tr>
                <tr>
                  <th><FormattedMessage id="summary.time" /></th>
                  <td>{date.format('LT')}</td>
                </tr>
              </>
            )}
            {enableLocationSelection() && (
              <tr>
                <th><FormattedMessage id="summary.location" /></th>
                <td>{companyName}</td>
              </tr>
            )}
            {address && (
              <tr>
                <th><FormattedMessage id="summary.address" /></th>
                <td>{address}</td>
              </tr>
            )}
            {resourceName && (
              <tr>
                <th>{resourceLabel || <FormattedMessage id="summary.resource" />}</th>
                <td>{resourceName}</td>
              </tr>
            )}
            <tr>
              <th><FormattedMessage id={mappedServices.size > 1 ? 'summary.services' : 'summary.service'} /></th>
              <td>
                {mappedServices && mappedServices.valueSeq().map((service) => {
                  const {
                    name, serviceId, serviceDuration, price, priceFrom, maxPrice,
                    maxDuration, webShowDuration, webShowPrice
                  } = service.toObject();
                  const showDetails = webShowDuration || webShowPrice;

                  return (
                    <div key={serviceId}>
                      {name}{' '}
                      {showDetails && (
                        <small className="cb-details">
                          {webShowDuration && <span><Duration duration={maxDuration || serviceDuration} /></span>}
                          {webShowPrice && <span><Price price={maxPrice || price} priceFrom={priceFrom} /></span>}
                        </small>
                      )}
                    </div>
                  );
                })}
              </td>
            </tr>
          </tbody>
          {showCustomer && vehicleRegNo && (
            <tbody>
              <tr>
                <th><FormattedMessage id="summary.vehicle" /></th>
                <td>{vehicleRegNo}</td>
              </tr>
            </tbody>
          )}
          {showCustomer && hasCompany && (
            <tbody>
              {orgName && (
                <tr>
                  <th><FormattedMessage id="summary.company" /></th>
                  <td>{orgName}</td>
                </tr>
              )}
              {orgNo && (
                <tr>
                  <th />
                  <td>{orgNo}</td>
                </tr>
              )}
            </tbody>
          )}
          {showCustomer && customer && (
            <tbody>
              <tr>
                <th><FormattedMessage id="summary.customer" /></th>
                <td>{name}</td>
              </tr>
              <tr>
                <th />
                <td>{formatPhoneNumber(phone)}</td>
              </tr>
              {email && (
                <tr>
                  <th />
                  <td>{email}</td>
                </tr>
              )}
              {pno && !useForeignPno && (
                <tr>
                  <th />
                  <td>{pno}</td>
                </tr>
              )}
              {foreignPno && useForeignPno && (
                <tr>
                  <th />
                  <td>{foreignPno}</td>
                </tr>
              )}
            </tbody>
          )}
          {showCustomer && customer && customer.custom && customFields && !customFields.isEmpty() && (
            <tbody>
              {customFields.map((field) => {
                const { key, label } = field.toObject();
                const value = customer.custom[key];
                return value !== null && value !== undefined ? (
                  <tr key={key}>
                    <th>{label}</th>
                    <td><CustomFieldValue value={value} /></td>
                  </tr>
                ) : null;
              })}
            </tbody>
          )}
          {showCustomer && note && (
            <tbody>
              <tr>
                <th>{customMessageLabel || <FormattedMessage id="customer.note" />}</th>
                <td>{note}</td>
              </tr>
            </tbody>
          )}
          {showCustomer && paymentMethod && (
            <tbody>
              <tr>
                <th><FormattedMessage id="summary.card" /></th>
                <td>{cardDescription(paymentMethod.card)}</td>
              </tr>
            </tbody>
          )}
          {showCustomer && paymentRequired && !discountVoucher && (
            <tbody>
              {pricesExVat && (
                <tr>
                  <th><FormattedMessage id="summary.priceExVat" /></th>
                  <td><Price price={paymentAmountExVat} fractionDigits={2} /></td>
                </tr>
              )}
              {pricesExVat && Object.keys(vatPctAmounts).map((vatPct) => (
                <tr>
                  <th><FormattedMessage id="summary.vat" values={{ vatPct }} /></th>
                  <td><Price price={vatPctAmounts[vatPct]} fractionDigits={2} /></td>
                </tr>
              ))}
              <tr>
                <th><FormattedMessage id={paymentStatus === 'Paid' ? 'summary.amountPaid' : 'summary.amountToPay'} /></th>
                <td><Price price={paymentAmountIncVat} fractionDigits={2} /></td>
              </tr>
            </tbody>
          )}
          {showCustomer && paymentRequired && discountVoucher && (
            <tbody>
              <tr>
                <th><FormattedMessage id={paymentStatus === 'Paid' ? 'summary.amountPaid' : 'summary.amountToPay'} /></th>
                <td>
                  <Price price={paymentAmountIncVat} fractionDigits={2} />{' '}
                  <small className="cb-details cb-line-through">
                    <Price price={totalAmount} fractionDigits={2} />
                  </small>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { booking, settings, payment, customFields } = state;

  const slot = booking.get('slot');
  const resource = getCurrentResource(state);
  const resourceName = resource && resource.get('name');

  return {
    slot,
    resourceName: settings.get('resourceName') || resourceName,
    useForeignPno: booking.get('useForeignPno'),
    customer: booking.get('customer'),
    discountVoucher: payment.get('discountVoucher'),
    totalAmount: getTotalAmount(state),
    paymentMethod: payment.get('paymentMethod'),
    paymentStatus: booking.get('paymentStatus'),
    paymentRequired: payment.get('paymentRequired'),
    paymentAmountIncVat: booking.get('paymentAmountIncVat'),
    pricesExVat: settings.get('pricesExVat'),
    customFields: customFields.get('fields'),
    paymentAmountExVat: getPaymentAmountExVat(state),
    vatPctAmounts: getVatPctAmounts(state),
    companyName: getPreference(settings, web.companyName),
    resourceLabel: getPreference(settings, web.resourceLabel),
    customMessageLabel: getPreference(settings, web.customMessageLabel),
    mappedServices: getCurrentMappedServices(state),
    webSettings: getMergedWebSettings(state),
    address: getBookingAddress(state)
  };
};

export default connect(mapStateToProps)(Summary);
