import React from 'react';

const svgProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  fill: 'transparent',
  height: 16,
  width: 16
};

export const Info = () => (
  <svg {...svgProps} viewBox="0 0 330 330">
    <path d="M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z" />
    <path d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991 c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z" />
    <path d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z" />
  </svg>
);

export const ChevronUp = () => (
  <svg {...svgProps} viewBox="0 0 24 24">
    <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
  </svg>
);

export const ChevronDown = () => (
  <svg {...svgProps} viewBox="0 0 24 24">
    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
  </svg>
);

export const ChevronLeft = () => (
  <svg {...svgProps} viewBox="0 0 24 24" className="left">
    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
  </svg>
);

export const ChevronRight = () => (
  <svg {...svgProps} viewBox="0 0 24 24" className="right">
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
);

export const CheckMark = () => (
  <svg {...svgProps} viewBox="0 0 24 24">
    <path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z" />
  </svg>
);

export const XMark = () => (
  <svg {...svgProps} viewBox="0 0 320 512">
    <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
  </svg>
);

export const Clock = () => (
  <svg {...svgProps} viewBox="0 0 510 510">
    <path d="m255 0c-140.25 0-255 114.75-255 255s114.75 255 255 255 255-114.75 255-255-114.75-255-255-255zm0 459c-112.2 0-204-91.8-204-204s91.8-204 204-204 204 91.8 204 204-91.8 204-204 204z" />
    <path d="m267.75 127.5h-38.25v153l132.6 81.6 20.4-33.15-114.75-68.85z" />
  </svg>
);

export const CheckBoxCircle = () => (
  <svg {...svgProps} viewBox="0 0 510 510">
    <path d="M150.45,206.55l-35.7,35.7L229.5,357l255-255l-35.7-35.7L229.5,285.6L150.45,206.55z M459,255c0,112.2-91.8,204-204,204 S51,367.2,51,255S142.8,51,255,51c20.4,0,38.25,2.55,56.1,7.65l40.801-40.8C321.3,7.65,288.15,0,255,0C114.75,0,0,114.75,0,255 s114.75,255,255,255s255-114.75,255-255H459z" />
  </svg>
);
