import React from 'react';
import { connect } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

const Loading = ({ loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className="cb-loading">
      <PulseLoader color="rgba(0,0,0,0.1)" size="10px" margin="3px" />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { booking } = state;
  return {
    loading: booking.get('loading')
  };
};

export default connect(mapStateToProps)(Loading);
