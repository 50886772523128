import React from 'react';
import { Prompt } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ChevronRight } from '../base/images';
import BookingStatus from './booking-status';
import { Step } from '../../helpers/nav';
import config from '../../config';

const VerifyProgress = ({ intl, headerMessage, paymentProvider, paymentRefData }) => {
  const onOpenSwish = () => {
    const token = paymentRefData?.paymentRequestToken;
    window.location = token
      ? `swish://paymentrequest?token=${token}&callbackurl=`
      : 'swish://';
  };

  return (
    <>
      <Prompt
        message={(location) => {
          if (location.pathname.indexOf(Step.Confirmation) !== -1) {
            return true;
          }
          return intl.formatMessage({ id: 'verify.beforeUnload' });
        }}
      />
      <div className="cb-header">
        <h2><FormattedMessage id={headerMessage} /></h2>
      </div>
      {paymentProvider === 'Swish' ? (
        <div className="cb-payment-progress">
          <img src={`${config.images}/swish.svg`} alt="Swish" />
          <h3><FormattedMessage id="payment.swishHeader" /></h3>
          <p>
            <FormattedMessage id="payment.swishInfo" />
          </p>
          {config.isMobile && (
            <button type="button" className="cb-button" onClick={onOpenSwish}>
              <FormattedMessage id="payment.swishButton" />
              <ChevronRight />
            </button>
          )}
          <BookingStatus paymentProvider={paymentProvider} />
        </div>
      ) : (
        <div className="cb-verify-progress">
          <FormattedMessage id="verify.progress" />
        </div>
      )}
    </>
  );
};

export default injectIntl(VerifyProgress);
