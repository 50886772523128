/* eslint-disable global-require */
/* eslint-disable import/no-import-module-exports */

import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/index';

const middleware = [
  thunkMiddleware
];

if (process.env.NODE_ENV !== 'production') {
  const { logger } = require('redux-logger');
  middleware.push(logger);
}

const createStoreWithMiddleware = compose(applyMiddleware(...middleware))(createStore);

export default function configureStore() {
  const store = createStoreWithMiddleware(rootReducer);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers/index');
      store.replaceReducer(createStoreWithMiddleware(nextRootReducer));
    });
  }

  return store;
}
