/* eslint-disable key-spacing, quote-props */

const strings = {
  'select':                   'Vælg',
  'continue':                 'Fortsæt',
  'back':                     'Tilbage',
  'fromPrice':                'Fra {price}',
  'free':                     'Gratis',
  'durationHours':            '{hours} time(r)',
  'durationMinutes':          '{minutes} min.',
  'optional':                 'valgfrit',
  'yes':                      'Ja',
  'no':                       'Nej',

  'location.heading':         'Hvor vil du booke?',

  'service.heading':          'Hvad vil du booke?',
  'service.multiple':         'Book flere tjenester',
  'service.showMore':         'Mere',
  'service.notBookable':      'Kontakt os for booking',
  'service.noServices':       'Der er ingen tjenester at booke',

  'resource.heading':         'Hvem vil du booke?',
  'resource.select':          'Vælg {label}',
  'resource.showAll':         'Vis alle',
  'resource.noResources':     'Det finns ingenting att boka',

  'calendar.heading':         'Vælg en tid',
  'calendar.progress':        'Kontrollerer tilgængelighed',
  'calendar.noSlots':         'Ingen tider',
  'calendar.week':            'uge {week}',
  'calendar.today':           'i dag',
  'calendar.previous':        'Forrige uge',
  'calendar.next':            'Næste uge',
  'calendar.noneThisWeek':    'Der er ingen ledige tider i denne uge',
  'calendar.nextAvailable':   'Den næste ledige tid er på {nextAvailable}',
  'calendar.showAvailable':   'Vis ledige tider',
  'calendar.noneAtAll':       'Fandt ingen ledige tider inden for {duration}',
  'calendar.notAvailable':    'Tjenesten er ikke tilgængelig',

  'customer.heading':         'Indtast dine oplysninger',
  'customer.name':            'Navn',
  'customer.firstName':       'Fornavn',
  'customer.lastName':        'Efternavn',
  'customer.email':           'E-mail',
  'customer.confirmEmail':    'E-mail (igen)',
  'customer.phone':           'Telefon',
  'customer.confirmPhone':    'Telefon (igen)',
  'customer.pno':             'CPR-nummer',
  'customer.disabledPno':     'CPR-nummer',
  'customer.foreignPno':      'Andet ID-nummer',
  'customer.orgNo':           'CVR-nummer',
  'customer.orgName':         'Virksomhed',
  'customer.vehicleRegNo':    'Registreringsnr. (køretøj)',
  'customer.note':            'Besked',
  'customer.changeCard':      'Ændr kort',
  'customer.acceptTerms':     'Jeg accepterer vilkår og betingelser',
  'customer.allowForeignPno': 'Jeg har ikke et CPR-nummer',
  'customer.foreignPnoHelp':  'Indtast dit skattepersonnummer eller reservenummer',
  'customer.orgOptional':     'Företagsuppgifter behöver inte anges om du bokar som privatperson',

  'verify.heading':           'Gennemfør booking',
  'verify.confirmPinHeading': 'Vi har sendt en pinkode til {phone}',
  'verify.confirmPinMessage': 'Indtast PIN-koden for at gennemføre bookingen',
  'verify.confirmMessage':    'Er oplysningerne korrekte?',
  'verify.confirmBooking':    'Gennemfør booking',
  'verify.confirmAndPay':     'Book og betal {amount}',
  'verify.progress':          'Gennemfør din booking...',
  'verify.beforeUnload':      'Din booking er ikke gennemført, er du sikker på, du vil forlade siden?',

  'payment.heading':          'Betal for din booking',
  'payment.providerHeading':  'Hvordan vil du betale?',
  'payment.providerSwish':    'Betal med Swish',
  'payment.klarnaLater':      'Faktura via Klarna',
  'payment.klarnaOverTime':   'Delbetaling med Klarna',
  'payment.providerCard':     'Betal med kort',
  'payment.providerNone':     'Gratis booking',
  'payment.phoneNumber':      'Indtast dit telefonnummer',
  'payment.cardDetails':      'Indtast dine kortoplysninger',
  'payment.swishHeader':      'Åbn Swish-appen',
  'payment.swishInfo':        'Åbn Swish-appen, og følg instruktionerne for at gennemføre din booking.',
  'payment.swishButton':      'Åbn Swish-appen',
  'payment.providerNoneInfo': 'Din booking er gratis, bekræft for at fuldføre din booking.',

  'timer.remaining':          'Resterende tid: {time}',

  'expired.heading':          'Tiden er udløbet!',
  'expired.message':          'Tiden, du havde til at bekræfte din booking, er udløbet! ' +
                              'Gå venligst tilbage for at foretage en ny booking.',
  'expired.back':             'Gå tilbage',

  'confirmation.heading':     'Tak for din booking!',
  'confirmation.email':       'Der er sendt en bekræftelse til {email}.',
  'confirmation.reminder':    'Der sendes en påmindelse til {phone} {interval} timer før bookingen.',
  'confirmation.postInfo':    'Tryk på knappen for at gennemføre din booking.',
  'confirmation.postButton':  'Gennemfør booking',

  'summary.toggle':           'Vis booking',
  'summary.location':         'Sted',
  'summary.date':             'Dato',
  'summary.time':             'Tid',
  'summary.resource':         'Ressource',
  'summary.service':          'Tjeneste',
  'summary.services':         'Tjenester',
  'summary.address':          'Adresse',
  'summary.customer':         'Din information',
  'summary.vehicle':          'Køretøj',
  'summary.company':          'Virksomhed',
  'summary.priceExVat':       'Pris eks. moms',
  'summary.price':            'Pris',
  'summary.discount':         'Rabatt',
  'summary.vat':              'Moms {vatPct}%',
  'summary.amountToPay':      'At betale',
  'summary.amountPaid':       'Betalt',
  'summary.card':             'Kort',

  'validation.required':      'skal udfyldes',
  'validation.phone':         'ugyldigt telefonnummer',
  'validation.phoneHint':     'Nummeret skal starte med 0 eller en landekode (f.eks. 0045 eller +45)',
  'validation.phoneMismatch': 'matcher ikke',
  'validation.email':         'ugyldig e-mailadresse',
  'validation.emailMismatch': 'matcher ikke',
  'validation.pno':           'ugyldigt CPR-nummer',
  'validation.orgNo':         'ugyldigt CVR-nummer',
  'validation.orgNoRequired': 'skal udfyldes hvis virksomhed udfyldes',
  'validation.minimumAge':    'du skal være mindst {minimumAge} år',
  'validation.birthDate':     'du skal være født i {birthDate} eller tidligere',

  'placeholder.name':         'For- og efternavn',
  'placeholder.firstName':    'Fornavn',
  'placeholder.lastName':     'Efternavn',
  'placeholder.email':        'E-mail',
  'placeholder.confirmEmail': 'E-mail (igen)',
  'placeholder.phone':        'Telefon',
  'placeholder.confirmPhone': 'Telefon (igen)',
  'placeholder.pno':          'CPR-nummer',
  'placeholder.disabledPno':  'CPR-nummer',
  'placeholder.foreignPno':   'Andet ID-nummer',
  'placeholder.orgNo':        'CVR-nummer',
  'placeholder.orgName':      'Virksomhed',
  'placeholder.vehicleRegNo': 'Registreringsnr. (køretøj)',
  'placeholder.note':         'Besked',

  'error.unavailable':        'Kunne ikke finde kontoen. Tjek linket, og prøv igen.',
  'error.maintenance':        'Vi uppdaterar systemet med nya funktioner. Vänligen kom tillbaka senare.',
  'error.unknown':            'Der opstod en uventet fejl. Gå tilbage, og prøv igen.',
  'error.notAllowed':         'Booking er ikke tilladt. Kontakt bookingkontoret.',
  'error.slotTaken':          'Tiden er ikke længere tilgængelig. Gå tilbage, og vælg en ny tid.',
  'error.wrongPin':           'Der blev angivet en forkert SMS-kode. Kontrollér koden, og prøv igen.',
  'error.payment':            'Betalingen mislykkedes. Kontrollér oplysningerne, og prøv igen.',
  'error.paymentDeclined':    'Betalingen blev afbrudt. Gå tilbage, og prøv igen.',
  'error.paymentHeading':     'Betalingen mislykkedes',
  'error.paymentNA':          'Betaling er ikke tilgængelig for den valgte ressource.',
  'error.paymentRequiredNA':  'Tjenesten kræver betaling, men ingen betalingsmetoder er tilgængelige.',
  'error.paymentProviderNA':  'Betalingsmetoden er ikke tilgængelig. Prøv en anden betalingsmetode.',
  'error.swishNotEnrolled':   'Nummeret er ikke knyttet til Swish. Kontrollér oplysningerne, og prøv igen.',
  'error.swishAlreadyExists': 'Der er allerede en Swish-betaling i gang. Annullér betalingen i Swish-appen, og prøv igen.',
  'error.back':               'Gå tilbage',

  'error.refNotValid':        'Bookingreference mangler eller er ugyldig. Tjek linket, og prøv igen.',
  'error.refAlreadyUsed':     'Din bookingreference er allerede blevet brugt til en booking.',
  'error.alreadyBookedPno':   'Der er allerede en booking på dette CPR-nummer.',
  'error.alreadyBookedMob':   'Der er allerede en booking på dette telefonnummer.',

  'footer.exVatInfo':         'Alle priser er eksklusive moms',
  'footer.privacy':           'Politik for personoplysninger',
  'footer.cliento':           'Bookingsystem fra Cliento'
};

export default strings;
