/* eslint-disable camelcase */

export function isSet(value) {
  return value !== undefined && value !== null;
}

export function cardDescription(card) {
  const { brand, last4, exp_month, exp_year } = card;
  const brandName = brand && brand.charAt(0).toUpperCase() + brand.slice(1);
  return `${brandName} **** **** **** ${last4} (${exp_month}/${exp_year})`;
}
