import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import config from '../../config';

const ReduxIntlProvider = ({ messages, customTexts, children }) => {
  const customMessages = customTexts && customTexts.toObject();
  const composedMessages = { ...messages[config.locale], ...customMessages };
  return (
    <IntlProvider locale={config.locale} messages={composedMessages} textComponent={Fragment}>
      {children}
    </IntlProvider>
  );
};

function mapStateToProps(state) {
  const { settings } = state;
  return {
    customTexts: settings.get('customTexts')
  };
}

export default connect(mapStateToProps)(ReduxIntlProvider);
