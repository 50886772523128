import React from 'react';
import { FormattedTime } from 'react-intl';
import Price from '../intl/price';

const CalendarSlot = ({ onSelect, showExtended, time, resource }) => {
  const parts = time.split(':');
  const price = resource && resource.get('price');
  const priceFrom = resource && resource.get('priceFrom');
  const date = new Date().setHours(parts[0], parts[1]);
  return (
    <a href="#" className="cb-slot" onClick={onSelect}>
      <strong><FormattedTime value={date} /></strong>
      {showExtended && <small><br />{resource && resource.get('name')}</small>}
      {showExtended && price > 0 && <small><br /><Price price={price} priceFrom={priceFrom} /></small>}
    </a>
  );
};

export default CalendarSlot;
