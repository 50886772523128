import { fromJS, List } from 'immutable';

import { FETCH_REF_DATA_SUCCESS } from '../actions/ref-data';

export default function resourceMappings(state = List(), action = null) {
  switch (action.type) {
    case FETCH_REF_DATA_SUCCESS:
      return action.resourceMappings
        ? fromJS(action.resourceMappings)
        : state;

    default:
      return state;
  }
}
